import { Handout } from "components/handout/Handout"
import { RichTextEditor } from "components/richText/RichTextEditor"
import { useUser } from "contexts/UserContext"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useQuarterId } from "hooks/UseQuarterId"
import { useStudentId } from "hooks/UseStudentId"
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore"
import styled from "styled-components"
import DiscreteRadioButtons from "./DiscreteButton"
import { useContext, useEffect, useRef, useState } from "react"
import { FaCompress, FaExpand } from "react-icons/fa"
import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { PreviousNextButtons } from "./PreviousNextButtons"
import { PepTeacherContext } from "./ExamPrepTeacherContext"
import { Link, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { TranslationContext } from "contexts/TranslationContext"
import { MidtermPep } from "./peps/MidtermPep"
import { FinalPep } from "./peps/FinalPep"

export const ExamPrepTeacherPage = () => {
  return <HandoutOuter>
    <HandoutMain>
      <HandoutInner />
    </HandoutMain>
  </HandoutOuter>
}

const HandoutInner = () => {

  const examId = useParams().examId

  if(examId == 'final') {
    return <FinalPep/>
  }

  return <MidtermPep/>
}



const HandoutOuter = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
`

const HandoutMain = styled.div`
  max-width: 670px;
`

