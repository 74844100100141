import { Handout } from "components/handout/Handout"
import { RichTextEditor } from "components/richText/RichTextEditor"
import { useUser } from "contexts/UserContext"
import { doc, getFirestore, setDoc } from "firebase/firestore"
import { useQuarterId } from "hooks/UseQuarterId"
import { useStudentId } from "hooks/UseStudentId"
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore"
import styled from "styled-components"
import DiscreteRadioButtons from "../DiscreteButton"
import { useContext, useEffect, useRef, useState } from "react"
import { FaCompress, FaExpand } from "react-icons/fa"
import { PythonHighlighter } from "components/syntaxHighlighter/PythonHighlighter"
import { PreviousNextButtons } from "../PreviousNextButtons"
import { PepTeacherContext } from "../ExamPrepTeacherContext"
import { Link, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { TranslationContext } from "contexts/TranslationContext"

export const FinalPep = () => {
  const scrollToRef = useRef(null)

  const studentId = useStudentId()
  const qtrId = useQuarterId()
  const userId = useUser().uid

  const {
    signupsData,
    slotsData,
    classData,
    isPepTeacherContextLoading
  } = useContext(PepTeacherContext)

  const db = getFirestore();

  const pset4DocPath = `users/${studentId}/${qtrId}/pset4`
  const pset5DocPath = `users/${studentId}/${qtrId}/pset5`
  const pset6DocPath = `users/${studentId}/${qtrId}/pset6`
  const taScoresPath = `personalexamprep/${qtrId}/final/students/${studentId}/private/ta/scores`
  const taNotesPath = `personalexamprep/${qtrId}/final/students/${studentId}/private/ta/notes`

  // load the pset completions before you show anything...
  const [pset4Completions, completions4Loading] = useDocumentData(doc(db, pset4DocPath));
  const [pset5Completions, completions5Loading] = useDocumentData(doc(db, pset5DocPath));
  const [pset6Completions, completions6Loading] = useDocumentData(doc(db, pset6DocPath));
  const [taScores, taScoresLoading, taScoresError] = useDocumentData(doc(db, taScoresPath));


  const [fullScreenState, setFullScreenState] = useState(null)

  useEffect(() => {
    if (fullScreenState === 'minimized') {
      console.log("scrolling to ref")
      scrollToRef.current.scrollIntoView()
    }
  }, [fullScreenState])

  const psetsLoading = completions4Loading || completions5Loading || completions6Loading
  if (isPepTeacherContextLoading || psetsLoading || taScoresLoading) {
    return <></>;
  }

  if (taScoresError) {
    return <>Error</>
  }

  const getNComplete = (completions) => {
    const completeDict = completions?.corrects
    return completeDict ? Object.keys(completeDict).filter(k => completeDict[k]).length : 0
  }


  const nComplete4 = getNComplete(pset4Completions)
  const nComplete5 = getNComplete(pset5Completions)
  const nComplete6 = getNComplete(pset6Completions)
  const studentData = getStudentData(classData, studentId)

  const pset4Corrects = pset4Completions?.corrects


  const fullName = parseName(studentData)
  const firstName = fullName.split(' ')[0]



  const slotId = signupsData[studentId]
  const slotData = slotsData[slotId]

  const isFullScreenStudentWork = fullScreenState == 'full'



  return <>
    <div style={{ display: isFullScreenStudentWork ? 'none' : 'block' }}>
      <h1>{fullName}</h1>
      <h4>{studentData?.email}</h4>
      <MeetingInfo slotData={slotData} />
      <p>
        <b>pset4 done:</b> {nComplete4} / 14<br />
        <b>pset5 done:</b> {nComplete5} / 11<br />
        <b>pset6 done:</b> {nComplete6} / 8<br />
      </p>



      <hr />

      <h3>Intro (1 min)</h3>
      <ul>
        <li>Hi you must be <b>{firstName}</b>, my name is _____. I am one of the TAs in CS109. 😊</li>
        <li>Welcome to pep.<br />I am going to ask you a few questions to help gauge where you are.<br />Then I will make you a personalised study plan:</li>
        <li>Just so you know: You are not graded on how well you answer questions today.<br />You get full participation points by showing up.</li>
        <li>We only get 10 mins so let's jump right in.</li>
        <li>{firstName}, why don't you start by telling me a little bit about yourself!</li>
      </ul>
      <hr />

      <h3 ref={scrollToRef}>Review Solution (1 min)</h3>


      <p>A while ago, when you were working on PSet4 you came up with this solution. To warm us up, why don't you read over your work and walk me through what you did (and why).</p>


      <b>Question:</b>
      <div className="alert alert-primary mb-2">Your baby might be tired. Your prior belief that the baby is tired = 3/4.<br />
        If a baby is tired, the time in minutes until they rub their eyes is distributed as Exp(λ=3).<br />
        If a baby is not tired, the time in minutes until they rub their eyes is  Exp(λ=1).<br />
        A baby rubs their eyes after 2 mins. What is your updated belief that they are tired?

      </div>

    </div>

    <button className="btn btn-secondary btn-small" onClick={() => setFullScreenState(isFullScreenStudentWork ? 'minimized' : 'full')}>
      {isFullScreenStudentWork ? <FaCompress /> : <FaExpand />}
    </button><br />

    <StudentAssnWork
      psetId='pset4'
      qId='tired_baby'
      fullScreen={isFullScreenStudentWork}
    />

    <div style={{ display: isFullScreenStudentWork ? 'none' : 'block' }}>


      <DiscreteRadioButtons
        dbDocData={taScores}
        dbDocPath={taScoresPath}
        docKey="reviewTiredBaby"
      />
      <hr />

      <h3>Transfer (4 min)</h3>
      <p>Fantastic <b>{firstName}</b>. Now we are going to practice formalizing a more complex inference problem. This one is about Photon Counting CT Scans. Just as some interesting background Photon-counting CT (Computed Tomography) is thought to be one of the most significant technological advancements in body imaging in decades and a ton of the math happened here at Stanford. The second Photon-Counting CT machine in the US was installed at Stanford only last year.
      </p>

      <p>Also this problem is brand new and was almost on your final exam!</p>

      <div className="alert alert-primary">

        {/* You are in the middle of a Photon-counting CT scan. */}
        Your goal is to identify the material at a particular location in a body.
        Based on measurements taken so far you have a belief distribution over the material at the location (M): <br />
        P(M = bone) = 0.7 <br />
        P(M = muscle) = 0.2 <br />
        P(M = fat) = 0.1 <br /> <br />

        You shoot photons at the material at a particular angle for one second and have a sensor which counts how many photons arrive on the other side.
        Regardless of the material, the number of photons that arrive in one second will be a poisson process. <br />

        - If the material is bone, the rate of photons arriving in one second is λ_bone = 18 <br />
        - If the material is muscle, the rate of photons arriving in one second is λ_muscle = 24 <br />
        - If the material is fat, the rate of photons arriving in one second is λ_fat = 25 <br />
        (As an aside: the values of λ are calculated based on a physics property called the Beer-Lambert law) <br /> <br />

        20 photons arrive in one second. What is your posterior belief that the material is bone?
      </div>

      <DiscreteRadioButtons
        dbDocData={taScores}
        dbDocPath={taScoresPath}
        docKey="catInference"
      />
      <hr />

      <h3>Meta (1 min)</h3>

      <p>What gives away that it is an inference problem?<br />
        What would you do differently if it was a coding problem, and it asked for posterior probabilities for all materials? Coding problems tend to be some of the hardest on the exam.
      </p>



      <DiscreteRadioButtons
        dbDocData={taScores}
        dbDocPath={taScoresPath}
        docKey="inference"
      />

      <hr />

      <h3>Information Theory (3 mins)</h3>

      <p>We haven't seen a lot of information theory practice problems. But it will certainly be on the exam. Here are two versions of what an entropy question could look like for this CT test setup.</p>

      <p>Entropy is interesting here, because in an adaptive CT test, you should chose the next angle to minimize your entropy in the belief of the material.</p>

      <b>Information Theory (Easy)</b><br />
      <div className="alert alert-primary">
        What is the entropy of your posterior belief in M? Let p_bone, p_water, p_tissue be the posteriors calculated after part (a).
      </div>

      <b>Information Theory (Super Hard)</b><br />
      <div className="alert alert-primary">
        Before you observed the number of photons arriving, what was the expected entropy in your posterior belief in M?
        Assume it isn’t possible to get more than 100 photons in 1 second. Hint: you will have to think through all possible values of the number of photons arriving.
      </div>

      <DiscreteRadioButtons
        dbDocData={taScores}
        dbDocPath={taScoresPath}
        docKey="catEntropy"
      />


      <hr />

      <h3>MLE and Logistic Regression</h3>

      <p>That is all for the scheduled portion of PEP. You have probably been working hard on MLE and Logistic Regression recently. I have some extra resources for you! </p>

      <p>MLE tends to be one of the most straight forward questions on the final. You will have a distribution, you will write the log likelihood, you will derive it with respect to each parameter:{" "}<br />
        <a target="_blank" href="https://cs109psets.netlify.app/fall24/extra_mle">Extra MLE Problems</a>
      </p>

      <p>Logistic Regression is one of the hardest problems on the final for us to write. Instead of having you re-write your code on the final, we tend to ask a twist of logistic regression. Here is a compilation of all the logistic regression questions in one go: {" "}<br />
        <a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vT5bh_hjJi2mH6dpz-ygq0gFa4XDm8ic2Vm9Lf64Nu5e1R7yUhA5rgnhDdWrJz7rOI-Gij8BKM73bNV/pub">Logistic Regression on the CS109 Final</a>
      </p>
      <p>Aside: How prepared is the student for the other content beyond the midterm (MLE, Logistic Regression, CLT, Bootstrapping etc)</p>
      <DiscreteRadioButtons
        dbDocData={taScores}
        dbDocPath={taScoresPath}
        docKey="prepBeyond"
      />

      <hr />


      <h3>Outro (1 min)</h3>

      <p>Thank you so much <b>{firstName}</b>. That was fast paced. I appreciate you putting this extra time into getting ready.</p>

      <p>I am going to send you your personal exam prep summary by the end of the day.</p>

      <p>I have someone coming right now. Thank you so much and good luck getting ready for the final. The teaching team is rooting for you. </p>

      <PublishButton dbDocData={taScores} dbDocPath={taScoresPath} />


      <hr />
      <h3>Internal Insights (not seen by {firstName})</h3>
      <RichTextEditor
        firebaseDocPath={taNotesPath}
        collaborative={true}
        editable={true}
        user={userId}
      />
      <a target="_blank" href={`https://docs.google.com/document/d/1JL5POv9j0E7cJts-mP-LNRflak6i4HPbctCNB2Ho20Y/edit?usp=sharing`}>Link to shared TA notes Google Doc</a><br />
      <Link target="_blank" to={`/${qtrId}/personalprep/final/guide/${studentId}`}>{firstName}'s Guide</Link>
      <hr />
      <PreviousNextButtons />
    </div>
  </>
}

const MeetingInfo = ({ slotData }) => {

  if (!slotData) {
    return <></>
  }
  return <>

    <p>{slotData.date} at {slotData.time} in {slotData.location}<br />
      TA: {slotData.ta}</p>
    <hr />
  </>
}

const PublishButton = ({ dbDocData, dbDocPath }) => {
  const isPublished = dbDocData?.isPublished

  const onClick = () => {
    const newState = !isPublished

    if (newState == false) {
      Swal.fire({
        title: 'Are you sure?',
        text: "This will unpublish the guide and the student will no longer be able to see it.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, unpublish it!'
      }).then((result) => {
        if (result.isConfirmed) {
          setDoc(doc(getFirestore(), dbDocPath), { isPublished: newState }, { merge: true });
        }
      })
    } else {
      setDoc(doc(getFirestore(), dbDocPath), { isPublished: newState }, { merge: true });
    }
  }

  const text = isPublished ? 'Unpublish' : 'Publish' + ' Guide'
  return <>
    <button onClick={() => onClick()} className="btn btn-primary">{text}</button>
  </>
}

const ReviewSolutionIntro = ({ firstName, completeDict }) => {
  const reviewProblemComplete = completeDict?.airlines

  if (!reviewProblemComplete) {
    return <><p><b>{firstName}</b>, This next problem starts with solving problem 2 on PSet3. In case its helpful, about 51% of the class has had a chance to solve this problem. I strongly recommend that you find time to finish this last PSet by the Friday 2pm deadline.</p>
      <p>Lets feed two birds with one scone. Here is the problem:</p>
    </>


  }

  return <p><b>{firstName}</b>, let's move on to the next question. The next few problems are going to build off one of the questions on your PSet. To warmup, remind yourself of the problem and walk me through your solution</p>
}



const PSetProblemButtons = ({ completeDict, dbDocData, dbDocPath, docKey }) => {
  const value = dbDocData?.[docKey]
  const db = getFirestore();
  const valueToText = {
    'notStarted': 'Not Started',
    'started': 'Started',
    'complete': 'Finished'
  }

  useEffect(() => {
    if (!value) {
      // if the problem is complete, set the status to complete
      if (completeDict?.airlines) {
        setDoc(doc(db, dbDocPath), { [docKey]: 'complete' }, { merge: true });
      }
    }
  }, [value])

  // three buttons: not started, started, complete
  return <div className="d-flex flex-row" style={{ gap: 20 }}>
    {['notStarted', 'started', 'complete'].map((v) => {
      const color = value === v ? 'btn-outline-primary' : 'btn-outline-secondary'
      return <button
        key={v}
        className={`btn ${color}`}
        onClick={() => {
          const newValue = value === v ? null : v
          setDoc(doc(db, dbDocPath), { [docKey]: newValue }, { merge: true });
        }}
      >
        {valueToText[v]}
      </button>
    })}
  </div>
}



const StudentAssnWork = ({ psetId, qId, fullScreen }) => {
  const studentId = useStudentId()
  const qtrId = useQuarterId()
  const userId = useUser().uid
  const firebaseDocPath = `/users/${studentId}/${qtrId}/${psetId}/answers/${qId}`

  const editerOuterStyle = fullScreen ? {
    flex: 1,
    flexGrow: 1,
    height: '100px',
    display: 'flex',
    maxWidth: '100vw',
  } : {
    marginBottom: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '5px'
  }

  const innerStyle = fullScreen ? {
    maxWidth: 670,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  } : {}

  const outerStyle = fullScreen ? {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    height: 'calc(100vh - 100px)',

  } : {}

  return <div style={outerStyle}>
    <div style={innerStyle}>
      {fullScreen ? <><h3>Sleepy Baby</h3><hr /></> : <b>Student answer:</b>}

      <div style={{ display: fullScreen ? 'block' : 'none' }}>
        <b>The question:</b>
        <div className="alert alert-primary mb-2">Your baby might be tired. Your prior belief that the baby is tired = 3/4.<br />
          If a baby is tired, the time in minutes until they rub their eyes is distributed as Exp(λ=3).<br />
          If a baby is not tired, the time in minutes until they rub their eyes is  Exp(λ=1).<br />
          A baby rubs their eyes after 2 mins. What is your updated belief that they are tired?
        </div>
        <b>Your answer:</b>
      </div>

      <div style={editerOuterStyle}>
        <RichTextEditor
          user={userId}
          editable={false}
          firebaseDocPath={firebaseDocPath}
          collaborative={true}
          minHeight={300}
        />
      </div>
    </div>
  </div>
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

function getStudentData(classData, studentId) {
  // classData.students is an array of objects
  const students = classData.students
  if (!students) return null
  for (const student of students) {
    if (student.uid === studentId) {
      return student
    }
  }
}

function parseName(studentData) {
  // names are written like this <FamilName>,<GivenName> <MiddleName>
  // we want to display them like <GivenName> <MiddleName> <FamilyName>
  if (!studentData) return ''
  const name = studentData.name
  const parts = name.split(',')
  if (parts.length === 1) return name
  const [familyName, givenName] = parts
  const givenParts = givenName.split(' ')
  const givenNameParts = givenParts.map(toTitleCase)
  return `${givenNameParts.join(' ')} ${familyName}`
}