import React, { useState, useEffect, useMemo, useContext } from 'react';
import {
  FaBars,
} from "react-icons/fa";
import { Aside } from "./components/Aside.js"
import { Question } from "./Question.js"
import {
  useDocumentData,
  useDocumentDataOnce,
  useCollectionOnce,
} from "react-firebase-hooks/firestore";
import {
  useParams
} from "react-router-dom";
import axios from 'axios'
import API_ROUTE from "../ApiRoute.js"
import { PSetSplash } from './PSetSplash.js';
import { PSetPrint } from './PSetPrint.js';
import Swal from 'sweetalert2'
import { firestore } from "firebaseApp.js";
import useSound from 'use-sound';
import useDebouncedCallback from "use-debounce/lib/useDebouncedCallback.js";
import { EVENT_TYPES, useEvents } from 'components/general/realtime/Events.js';
import { doc, getFirestore } from 'firebase/firestore';
import { UserContext, useUser } from 'contexts/UserContext.js';

/**`
 * This is the main component for the pset page!
 */



export const PSetPage = (props) => {


  let { qtrId, psetId, forcedStudentId } = useParams();

  const { userMetaData, userMetaDataLoading } = useContext(UserContext)

  let user = useUser()
  console.log('user', user)
  if (forcedStudentId) {
    user = makeForcedUser(forcedStudentId)
  }

  let psetUrl = `psets/${qtrId}/${psetId}/public`

  // get public pset data
  var [publicPsetData, publicPsetLoading, publicPsetLoadingErr] = useDocumentDataOnce(
    doc(getFirestore(), psetUrl)
  );

  // get correct / psetParams for pset
  var [studentPsetData, studentDataLoading, studentDataLoadingErr] = useDocumentData(
    doc(getFirestore(), `users/${user.uid}/${qtrId}/${psetId}`)
  );


  // get any feedback for all answers. I want this memoized
  const memoizedFeedbackRequest = useMemo(() => {
    let feedbackPath = `users/${user.uid}/${qtrId}/${psetId}/feedback`
    return firestore.collection(feedbackPath)
  }, [user.uid, qtrId, psetId]);
  var [feedbackCollection, feedbackLoading, feedbackLoadingErr] = useCollectionOnce(memoizedFeedbackRequest);

  // display nothing for the ms while the data is loading
  if (studentDataLoading || publicPsetLoading || userMetaDataLoading) return <></>

  console.log('userId', user.uid)
  if (publicPsetData == undefined) {
    return <>No pset found with psetId {psetId}</>
  }

  if (feedbackLoading && publicPsetData.gradesReleased) return <></>

  let allFeedback = {}
  if (feedbackCollection) {
    for (const doc of feedbackCollection.docs) {
      allFeedback[doc.id] = doc.data()
    }
  }


  // go ahead and generate if necessary...
  if (publicPsetLoadingErr) {
    console.error(publicPsetLoadingErr)
    // this could either be as a result of no internet, or, their pset isn't ready...
    return <>Unable to load pset data. Perhaps you don't have internet? Try again!</>
  }
  if (studentDataLoadingErr) {
    console.error(studentDataLoadingErr)
    console.log(`users/${user.uid}/${qtrId}/${psetId}`)
    // this could either be as a result of no internet, or, their pset isn't ready...
    return <>Unable to load solution data. Perhaps you don't have internet? Try again!</>
  }

  // if you didn't get pset corrects from the user, they might not have a pset created
  if (!studentPsetData || !studentPsetData.corrects) {
    requestMakePset(user, qtrId, psetId)
  }


  document.title = publicPsetData.title + ' ' + user.uid;




  return (
    <>
      <PSetPageWithData
        user={user}
        studentPsetData={initializeStudentPsetData(studentPsetData)}
        publicPsetData={publicPsetData}
        userMetaData={userMetaData}
        allFeedback={allFeedback}
      />
    </>
  )
}


const PSetPageWithData = (props) => {

  const [menuToggled, setMenuToggled] = useState(false);
  const handleToggleSidebar = (value) => {
    setMenuToggled(value);
  };

  return <>
    <div className="app">
      <Aside
        user={props.user}
        studentPsetData={props.studentPsetData}
        publicPsetData={props.publicPsetData}
        toggled={menuToggled}
        setToggled={handleToggleSidebar}
      />
      <div className="content">
        <div 
          className="btn btn-secondary btn-toggle" 
          style={{ margin: '5px' }} 
          onClick={() => handleToggleSidebar(true)}
        >
          <FaBars />
        </div>
        <PageBody
          user={props.user}
          publicPsetData={props.publicPsetData}
          studentPsetData={props.studentPsetData}
          userMetaData={props.userMetaData}
          allFeedback={props.allFeedback}
        />
      </div>
    </div>
  </>

}

const PageBody = (props) => {
  let { qId } = useParams();

  if (qId === 'splash') {
    return <PSetSplash {...props} />
  }

  if (qId === 'submit') {
    return <PSetPrint {...props} />
  }


  return <Question {...props} />
}

function requestMakePset(user, qtrId, psetId) {
  user.getIdToken(true)
    .then(function (token) {
      let URL = `${API_ROUTE}makePset`;
      axios.post(URL, {
        token: token,
        psetId: psetId,
        qtrId: qtrId
      }).then(response => {
        // this is default
      }).catch(err => {
        console.log('makePset err', err)
      });
    });
}

function initializeStudentPsetData(fromServer) {
  // welcome to the pset for  your first time
  // this will prevent the page from crashing if
  // we don't have correct data for you
  if (fromServer == undefined) {
    return {
      'corrects': {}
    }
  }
  if (fromServer['corrects'] == undefined) {
    fromServer['corrects'] = {}
    return fromServer
  }
  return fromServer
}

function makeForcedUser(forcedStudentId) {
  return {
    uid: forcedStudentId,
    displayName: 'Forced Student',
    email: 'noemail@stanford.edu',
    emailVerified: false,
    isAnonymous: false,
    metadata: undefined,
    providerData: undefined,
    refreshToken: undefined,
    tenantId: undefined,
    delete: undefined,
    getIdToken: undefined,
    getIdTokenResult: undefined,
    reload: undefined,
    toJSON: undefined,
    photoURL: undefined,
    phoneNumber: undefined,
    providerId: undefined
  }
}