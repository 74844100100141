import React, { useState, useEffect, useRef } from "react";
import {
    collection,
    doc,
    getDoc,
    getFirestore,
    query,
  } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { Navigate } from "react-router-dom";

export const HomeRedirect = () => {
    const [appData, isLoading] = useDocumentDataOnce(doc(getFirestore(), `global/app`))
    if(isLoading) {
        return <></>
    }
    const qtrId = appData.currentTerm
    console.log(qtrId)
    return <Navigate
        to={`/${qtrId}`}
    />
}