import { Handout } from "components/handout/Handout";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useQuarterId } from "hooks/UseQuarterId";
import { useStudentId } from "hooks/UseStudentId";
import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import CryptoJS from 'crypto-js'; // Import crypto-js for hashing
import { contentTodos } from "./finalGuideData/FinalContentTodos";
import { useUser } from "contexts/UserContext";
import { FinalReferences } from "./finalGuideData/FinalReferences";


export const FinalPrepStudentView = () => {
  return <Handout element={<Guide />} />;
};

const Guide = () => {
  return (
    <GuideOuter>
      <GuideContent />
    </GuideOuter>
  );
};

const GuideContent = () => {

  const studentId = useStudentId()
  const qtrId = useQuarterId()



  const db = getFirestore();

  const user = useUser()
  const userFirstName = user.displayName.split(' ')[0]

  const taScoresPath = `personalexamprep/${qtrId}/final/students/${studentId}/private/ta/scores`

  const studyPlanDataPath = `personalexamprep/${qtrId}/final/students/${studentId}/planchecklist`

  // load the pset completions before you show anything...
  // const [psetCompletions, completionsLoading, completionsError] = useDocumentData(doc(db, psetDocPath));

  const [studentPlanData, studentPlanDataLoading, studentPlanDataError] = useDocumentData(doc(db, studyPlanDataPath));
  const [taScores, taScoresLoading, taScoresError] = useDocumentData(doc(db, taScoresPath));

  if (studentPlanDataLoading || taScoresLoading) {
    return <></>
  }

  return (
    <>
      <h1>Final Study Plan for {userFirstName}</h1>
      <div className="alert alert-primary">
        <b>Notes from the meeting:</b> Hey {userFirstName}, it was such a pleasure to meet with you today. Thanks so much for taking your time! Best of luck getting ready for the final this coming week. I am rooting for you and I hope you both rock the exam and enjoy the process of taking a deep dive into the material. I have some light notes on how to think about studying the core concepts for you and then I included a ton of pro tips and study todo's compiled by the whole teaching team.
        </div>

      <HowWasYourPep/>

      <hr />

      <FinalReferences/>
      
      <hr/>

      <Content
        studentPlanData={studentPlanData}
      />

      <>Come back later today for more!</>

    </>
  );
};

const Content = ({studentPlanData}) => {

  const contentTodosEdited = JSON.parse(JSON.stringify(contentTodos));
  return <>
    <h2>Content Highlights</h2>
    <p>Check off each concept when you are feeling comfortable:</p>
    <CheckboxList
      data={studentPlanData}
      outline={contentTodosEdited}
    />
  </>
}

const HowWasYourPep = () => {
  const examId = 'final'
  const qtrId = useQuarterId()
  const userId = useUser().uid
  const dbPath = `/personalexamprep/${qtrId}/${examId}/students/${userId}/howWasFinalPep`
  const db = getFirestore();

  const [howWasFinalPep, howWasFinalPepLoading, howWasFinalPepError] = useDocumentData(doc(db, dbPath));

  if(howWasFinalPepLoading || howWasFinalPepError) {
    return <></>
  }

  return <>
  How did we do during your final PEP? This will not be shared with your TA.
    <LikertButtons keyTextMap = {{
      1: 'Poor',
      2: 'Fair',
      3: 'Good',
      4: 'Great',
      5: 'Amazing'
    }}
    dbPath={dbPath}
    dbData={howWasFinalPep}
    dbKey = {'howWasFinalPep'}
    />
  </>
}

const LikertButtons = ({keyTextMap, dbPath, dbData, dbKey}) => {
  const value = dbData?.[dbKey]

  const db = getFirestore();
  const onClick = async (newValue) => {
    const newData = { [dbKey]: newValue };
    const docRef = doc(db, dbPath);
    try {
      await setDoc(docRef, newData, { merge: true });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  
  }

  return <div>
    {
      Object.keys(keyTextMap).map((key) => {
        return <button
          key={key}
          onClick={() => onClick(key)}
          className={`btn ${value === key ? 'btn-primary' : 'btn-outline-primary'}`}
        >
          {keyTextMap[key]}
        </button>
      })
    }
  </div>
}

const ContentTodos = ({ studentPlanData, taScores }) => {

  const contentTodosEdited = JSON.parse(JSON.stringify(contentTodos));

  function inject(item, score, feedbackMap) {
    if (score === null) {
      return
    }
    const feedback = feedbackMap[score]
    if (!feedback) {
      return
    }
    item.taInsight = feedback
  }

  console.log(taScores)

  // core probability feedback
  inject(contentTodosEdited[0], taScores?.warmupQuestion, {
    'n/a': null,
    1: <div className="alert alert-danger"><b>Start here!</b> This content is 10/10 important and is a basis for other material. Core Probability the right place to start your review.</div>,
    2: <div className="alert alert-warning"><b>Start here!</b> This content is 10/10 important and is a basis for other material. Core Probability the right place to start your review.</div>,
    3: <div className="alert alert-warning"><b>Start here!</b> This content is 10/10 important and is a basis for other material. Core Probability the right place to start your review.</div>,
    4: <div className="alert alert-primary"><b>Review is always a good idea:</b> You look solid here! We didn't touch on everything from Core Probability in the warmup (eg Bayes or Law of Total Probability) so make sure you know those too.</div>,
    5: <div className="alert alert-primary"><b>Looking good.</b> You look solid here! We didn't touch on everything from Core Probability in the warmup (eg Bayes or Law of Total Probability) so make sure you know those too.</div>
  })


  // random variable feedback
  const reviewSolutionScore = taScores?.reviewSolution
  if (reviewSolutionScore > 0 && reviewSolutionScore < 4) {
    const severity = reviewSolutionScore < 2 ? 'danger' : 'warning'
    const randomVariableSerious = <div className={`alert alert-${severity}`}><b>More study here!</b> Make sure to spend a lot of time on Random Variables. Binomial is a good start, then focus on Poisson, Normal, Exponential, Uniform, Geometric, NegBinomial (in that order)</div>
    contentTodosEdited[1].taInsight = randomVariableSerious
  } else {
    const gaussApproxScore = taScores?.gaussianApprox
    const general = `Gaussian approximation is one of the many "extras" to know. Make sure to review the broader set of tasks related to random variables: Expectation, Variance, using a CDF vs PDF etc.`
    if (gaussApproxScore < 4 || gaussApproxScore == 0 || gaussApproxScore == 'n/a') {
      const gaussApproxMessage = <div className={`alert alert-warning`}><b>Getting there.</b> The Binomial looked solid! {general}</div>
      contentTodosEdited[1].taInsight = gaussApproxMessage
    } else {
      const gaussApproxMessage = <div className={`alert alert-primary`}><b>Solid footing.</b> The Binomial and Gaussian approximation looked great! {general}</div>
      contentTodosEdited[1].taInsight = gaussApproxMessage
    }
  }

  // bayesian inference feedback
  const generalInference = `We didn't touch on Law of Total Probability with random variables and we didn't mix continuous and discrete. Make sure you know those too!`
  inject(contentTodosEdited[2], taScores?.inference, {
    'n/a': <div className="alert alert-warning"><b>Make sure to get here</b> This content is 10/10 important for the final and also for the next half of class. Inference is best learned once you feel comfortable with the core and random variables. Make sure to get to mastery of this before the test.</div>,
    1: <div className="alert alert-warning"><b>Make sure to get here</b> This content is 10/10 important for the final and also for the next half of class. Inference is best learned once you feel comfortable with the core and random variables. Make sure to get to mastery of this before the test.</div>,
    2: <div className="alert alert-warning"><b>Make sure to get here</b> This content is 10/10 important for the final and also for the next half of class. Inference is best learned once you feel comfortable with the core and random variables. Make sure to get to mastery of this before the test.</div>,
    3: <div className="alert alert-warning"><b>You are getting there</b> This content is 10/10 important for the final and also for the next half of class. Inference is best learned once you feel comfortable with the core and random variables. Make sure to get to mastery of this before the test.</div>,
    4: <div className="alert alert-primary"><b>Rock on</b> Inference is not easy. {generalInference}</div>,
    5: <div className="alert alert-primary"><b>Wow</b> Looking super. {generalInference}</div>
  })

  return <>
    <h2>Content Highlights</h2>
    <CheckboxList
      data={studentPlanData}
      outline={contentTodosEdited}
    />
  </>
}

const ProTips = ({ studentPlanData }) => {
  return (
    <>
      <h2>3. Pro Tips</h2>
      <CheckboxList
        data={studentPlanData}
        outline={null}
      />
    </>
  );
};

const StudyPlanTodos = ({ studentPlanData }) => {
  return (
    <>
      <h2>2. Study Plan To-Dos</h2>
      <CheckboxList
        data={studentPlanData}
        outline={null}
      />
    </>
  );
};

const CheckboxList = ({ data, outline }) => {
  return <List>
    {outline.map((todo, index) => (
      <ListItem key={index}>
        <Checkbox data={data} taskStr={todo.task} />
        <TextWrapper>
          <Insight>{todo.task}</Insight>
          {todo.details && <span><br /><TaInsight taInsight={todo?.taInsight} /> {todo.details}</span>}

          {todo.subtasks && (
            <NestedList>
              {todo.subtasks.map((subtask, subIndex) => (
                <ListItem key={subIndex}>
                  <Checkbox data={data} taskStr={subtask.task} />
                  <TextWrapper>{subtask.task}</TextWrapper>
                </ListItem>
              ))}
            </NestedList>
          )}

        </TextWrapper>
      </ListItem>
    ))}
  </List>
}

const TaInsight = ({ taInsight }) => {
  if (!taInsight) {
    return null
  }
  return <>{taInsight}</>

}

const Checkbox = ({ data, taskStr }) => {

  const studentId = useStudentId()
  const qtrId = useQuarterId()
  const db = getFirestore();
  const examId = 'final'

  const studyPlanDataPath = `personalexamprep/${qtrId}/${examId}/students/${studentId}/planchecklist`

  // Generate a SHA-256 hash based on taskStr
  const dataKey = CryptoJS.SHA256(taskStr).toString(CryptoJS.enc.Hex); // Convert to hex string


  const isSelected = data?.[dataKey]

  const onClick = async () => {
    const newData = { [dataKey]: !isSelected };
    const docRef = doc(db, studyPlanDataPath);

    try {
      await setDoc(docRef, newData, { merge: true });
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return <CheckboxWrapper>
    <input type="checkbox" checked={!!isSelected} onChange={onClick} />
  </CheckboxWrapper>
}

const GuideOuter = styled.div``;

const List = styled.ul`
  list-style-type: none;
  padding-left: 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: 7;
`;

const CheckboxWrapper = styled.div`
  margin-right: 10px;
  margin-top: 1px;

  input[type="checkbox"] {
    transform: scale(1.3); /* Increase or decrease the value to adjust size */
  }
`;

const TextWrapper = styled.div`
  flex: 1;
`;

const NestedList = styled.ul`
  list-style-type: none;
  padding-left: 20px;
`;

const Insight = styled.span`
  font-weight: 800;
  color: #3a6da3; /* A slightly more saturated blue */
`

function hash(taskStr) {
  throw new Error("Function not implemented.");
}
function useProps() {
  throw new Error("Function not implemented.");
}

