

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import React, { useState, useEffect, createContext, useContext } from "react";
import { Button } from 'react-bootstrap';
import styled from "styled-components"
import { useLocation, useNavigate } from "react-router-dom";
import { EmailLinkform } from './EmailLinkForm';
import { EmailPasswordForm } from './EmailPasswordForm';
import { CustomAuth } from './CustomAuth';
import { LoginProvider, LoginContext, LoginScreen } from './LoginContext';
import { EmailSentNotice } from './EmailSentNotice';
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
} from 'firebase/auth';
import { SignInButton, SignInButtonFlat } from './SignInButton';
import { OrSeparator } from './OrSeparator';
import Swal from 'sweetalert2';
import { Loading } from '../loading/Loading';
import { useUser } from 'contexts/UserContext';

export const LoginPublic = () => {
  console.log('LoginPublic')

  const user = useUser()
  const [showSignIn, setShowSignIn] = useState(true)
  const location = useLocation()
  const navigate = useNavigate()
  const forgotPasswordText = "Enter the email address associated with your CS109 account. We will send you further instructions for resetting your password."
  const emailSignInText = "Enter the email address associated with your CS109 account. We will send you a link to sign in."
  const url = window.location.origin + window.location.pathname
  const auth = firebase.auth()
 
  // const navigate = useNavigate()
  const actionCodeSettings = {
    url: url,
    handleCodeInApp: true,
  };

  const showEmailAlert = async () => {
    try {
      const { value: email } = await Swal.fire({
        title: "Input email address",
        input: "email",
        inputLabel: "Your email address",
        inputPlaceholder: "Enter your email address"
      });
      const result = await signInWithEmailLink(auth, email, window.location.href)
      window.localStorage.removeItem('emailForSignIn');

      // history.pushState(null, "", location.href.split("?")[0]);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message,
      });
    }
  }

  useEffect(() => {
    if (user) {
      // TODO: navigate to the correct page
      navigate(`${location.pathname}`)

    } else if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        showEmailAlert()
      } else {
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem('emailForSignIn');
            // history.pushState(null, "", location.href.split("?")[0]);

          })
          .catch((error) => {
            window.alert(error)
          });
      }
    }
  }, [])

  const handleEmailLinkSignIn = async (auth, magicLinkEmail, actionCodeSettings) => {
    await sendSignInLinkToEmail(auth, magicLinkEmail, actionCodeSettings)
    window.localStorage.setItem('emailForSignIn', magicLinkEmail)
  }

  const InitialLanding = () => {
    const {setLoginState} = useContext(LoginContext)
    return (
      <div className="d-flex flex-column mt-3">
        <CustomAuth auth={auth} />
        <OrSeparator />
        <SignInButtonFlat
          className='mb-2'
          variant='light'
          onClick={() => {
            setShowSignIn(false)
            setLoginState(LoginScreen.LOGIN_FORM)
          }}>
          Create an Account
        </SignInButtonFlat>
        <SignInButtonFlat
          className='mb-2'
          variant='light'
          onClick={() => {
            setShowSignIn(true);
            setLoginState(LoginScreen.LOGIN_FORM)
          }}>
          I Have an Account
        </SignInButtonFlat>

      </div>
    )
  }

  const LoginForm = () => {
    return (
      <div>
        <div className="d-flex flex-column">
          <EmailPasswordForm
            auth={auth}
            showSignIn={showSignIn}
          />
          <OrSeparator />
          <CustomAuth auth={auth} btnVariant='outline-secondary' />
        </div>
        <div style={{ maxWidth: "500px" }} className="text-center">

          <SwitchText>{showSignIn ? "New to Code In Place?" : "Already have an account?"}</SwitchText>
          <SignInButtonFlat
            style={{ width: "100%" }}
            variant='light'
            onClick={() => setShowSignIn(!showSignIn)}
          >
            {showSignIn ? "Create Account" : "Sign In"}
          </SignInButtonFlat>
        </div>
      </div>
    )
  }

  const LoginBody = () => {

    const {loginState} = useContext(LoginContext)
    switch (loginState) {
      case LoginScreen.INIT_LANDING: return (<InitialLanding />);
      case LoginScreen.UNVERIFIED: return (
        <EmailSentNotice emailHandler={sendEmailVerification} handlerArgs={[user, actionCodeSettings]} type="Verification" />
      );
      case LoginScreen.LOGIN_FORM: return (
        <div>
          <div className="mt-3">
            <LoginForm />
          </div>
        </div>
      );
      case LoginScreen.FORGOT_PASWORD: return (
        <EmailLinkform
          auth={auth}
          emailHandler={sendPasswordResetEmail}
          actionCodeSettings={actionCodeSettings}
          title={"Reset Password"}
          body={forgotPasswordText}
          type="Password reset"
        />
      )

      case LoginScreen.EMAIL_LINK: return (
        <EmailLinkform
          auth={auth}
          emailHandler={handleEmailLinkSignIn}
          actionCodeSettings={actionCodeSettings}
          title={"One-time link"}
          body={emailSignInText}
          type="One-time link"
        />
      )
    }
  }

  const searchParams = new URLSearchParams(location.search);
  const apiKey = searchParams.get('apiKey');
  const isLoading = apiKey
  

  return (
    <OuterWrapper>
      {isLoading ?
        <Loading />
        :
        <LoginWrapper className="d-flex flex-column align-items-center">
          <StanfordLogo
            src={process.env.PUBLIC_URL + "/stanford.png"}
            alt="Stanford Logo"
          />
          <h1>CS109 App</h1>
          <LoginProvider>
            <LoginBodyOuter>
              <LoginBody />
            </LoginBodyOuter>
          </LoginProvider>
        </LoginWrapper>
      }
    </OuterWrapper>

  );
};



export const GenericBrandedWrapper = ({ children }) => {

  return (
    <OuterWrapper>
      <LoginWrapper className="d-flex flex-column align-items-center">
        <StanfordLogo
          src={process.env.PUBLIC_URL + "/stanford.png"}
          alt="Stanford Logo"
        />
        <h1>CS109 App</h1>
        {children}

      </LoginWrapper>
    </OuterWrapper>
  )
}

const LoginWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  margin-bottom: 40px;
`

const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh
`



const StanfordLogo = styled.img`
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  flex-grow:1;
  max-height:300px;
`;

const LoginBodyOuter = styled.div`
`

const SwitchText = styled.div`
text-align: center;
margin-top:20px;
`


