import axios from 'axios'
import API_ROOT from "../ApiRoute.js"

export const checkIsPastDeadline = function(publicPsetData, studentPsetData) {
  let currTime = new Date()
  // user might not have this... field set
  if(studentPsetData.extension){
    const personalDueDate = new Date(studentPsetData.extension.dueDate);
    return (currTime.getTime() > personalDueDate.getTime())
  } else {
    // check if it is past the grace period.
    let gracePeriodDeadline = getGracePeriodDeadline(publicPsetData)
    return (currTime.getTime() > gracePeriodDeadline.getTime())
  }
}

export const getGracePeriodDeadline = function(publicPsetData) {
  // if there is a specified grace period deadline, use that
  if(publicPsetData.grace) {
    return new Date(publicPsetData.grace)
  }
  // otherwise the default is 24 hours!
  const standardDeadline = new Date(publicPsetData.due)
  var gracePeriodDeadline = standardDeadline;
  gracePeriodDeadline.setHours(gracePeriodDeadline.getHours() + 24)
  return gracePeriodDeadline
}

export const isEditorActive = function() {
  let activeClass = document.activeElement.className
  return activeClass.includes('DraftEditor') 
            || activeClass.includes('TeXInput') 
            || activeClass.includes('form-control')
            || activeClass.includes('screenReaderEditor')
            || activeClass.includes('consoleBox')
            || activeClass.includes('resourceInput')
            || activeClass.includes('ProseMirror')
}

export const getPreviousQuestionId = function(qId, publicQuestionInfo) {
  for (var i = 0; i < publicQuestionInfo.length; i++) {
    let currQ = publicQuestionInfo[i]
    if(currQ['qId'] == qId) {
      if(i == 0) {
        return 'splash'
      }
      let lastQ = publicQuestionInfo[i-1]
      return lastQ['qId']
    }
  }
  return ''
}

export const getNextQuestionId = function(qId, publicQuestionInfo) {
  for (var i = 0; i < publicQuestionInfo.length; i++) {
    let currQ = publicQuestionInfo[i]
    if(currQ['qId'] == qId) {
      if(i == publicQuestionInfo.length - 1) {
        return 'submit'
      }
      let lastQ = publicQuestionInfo[i+1]
      return lastQ['qId']
    }
  }
  return ''
}

export const checkIsAdmin = function(userMetaData) {
  if(!userMetaData) {
    // you might not have any metadata
    return false
  }
  if(!userMetaData.role) {
    // you might not have a role
    return false
  } else {
    // and you might not be admin
    return userMetaData.role === 'admin'
  }
}

export const getQuestionInfo = function(qId, publicQuestionInfo) {
    for (var i = 0; i < publicQuestionInfo.length; i++) {
      let q = publicQuestionInfo[i]
      if(q['qId'] == qId) {
        q['index'] = i+1
        return q
      }
      // the question might have subparts!
      if(q['subparts']) {
        for(var j = 0; j < q['subparts'].length; j++) {
          let s = q['subparts'][j]
          if(s['qId'] ==  qId) {
            s['index'] = i+1
            return s
          }
        }
      }
    }
    return null;
  }

export const setAnswerExplanation = function(newValue) {
  
}

export const composeQuestion = function(template, params) {
    let composeParams = {}
    for(var key in params){
        composeParams[key] = params[key]
    }
    composeParams['break'] = '\n'
   
    var regexp = /{([^{]+)}/g;
      return template.replace(regexp, function(ignore, key){
            return (key = composeParams[key]) == null ? '' : key;
      });
  }

 export const generateNewPset = function(user, psetId, qtrId){
    // try not to request this many times :-)
    if(true) {
      
      user.getIdToken(true)
        .then(function (token) {
          let URL = `${API_ROOT}createStudentPset`
          axios.post(URL, {
            token:token,
            psetId:psetId,
            qtrId:qtrId
          }).then(response => {
            console.log(response)
            // setPsetGeneratingDone(true)
          }).catch(err => {
            console.error(err)
          });
        })
    }
    
  }