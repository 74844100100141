import React, { useEffect, useState } from "react";
import "firebase/compat/auth";
import { functions } from "firebaseApp";

/**
 * This context provides the server time to the client.
 * Caution: because of internet delays it could be incorrect up to 
 * about 1 second. Do not use it for anything which needs more precise
 * timing.
 * 
 * Stores the server time in the serverDelta variable. It is the difference
 * in ms between the server time and the client time.
 */

const defaultData = {
  getServerTimeMs: undefined,
  serverTimeLoaded: false,
};

export const TimeContext = React.createContext(defaultData);
export const TimeProvider = ({children}) => {
  const [serverTimeLoaded, setServerTimeLoaded] = useState(false);
  const [serverTimeDelta, setServerDelta] = useState(undefined);

  useEffect(()=> {
    const getServerTime = functions.httpsCallable('getServerTime');
    getServerTime().then((result) => {
      const serverTime = result.data.serverTime;
      const clientTime = Date.now();
      const serverDelta = serverTime - clientTime;
      setServerDelta(serverDelta)
    });
  },[])

  useEffect(() => {
    if(serverTimeDelta !== undefined) {
      setServerTimeLoaded(true);
    }
  }, [serverTimeDelta])


  const getServerTimeMs = () => {
    if(serverTimeDelta === undefined) {
      return undefined;
    }
    return Date.now() + serverTimeDelta;
  }
  


  return (
    <TimeContext.Provider
      value={{
        getServerTimeMs,
        serverTimeLoaded
      }}
    >
      {children}
    </TimeContext.Provider>
  );
};

