import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import styled from 'styled-components';

export const PythonHighlighter = ({ code, fontSize }) => {
    let safeFontSize = fontSize || "12px";
    return (
        <Outer style={{ fontSize: safeFontSize }}>
            <CodeWrapper>
                <SyntaxHighlighter language="python" style={{ whiteSpace: 'pre-wrap' }}>
                    {code}
                </SyntaxHighlighter>
            </CodeWrapper>
        </Outer>
    );
};

const Outer = styled.div`
    width: 100%;
    max-width: calc(100vw - 20px);
`;

const CodeWrapper = styled.div`
`;
