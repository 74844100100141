import React from 'react';

import { Router } from "./Router";
import './styles/style.scss';
import "bootstrap/dist/css/bootstrap.min.css";


function App() {
  document.title ="CS109 App"
    return (
        <Router />
    );
}

export default App;
