export const contentTodos = [
  {
    "task": "Practice MLE",
    "details": "MLE is a bread and butter algorithm. And! It is rather a straightforward problem type to study for. Each MLE problem looks very similar. You will have data, you will have a likelihood function (often for an unseen random variable, because we have already done MLE for the ones we have used in class). Take the log likelihood, and differentiate with respect to each param.",
  },
  {
    "task": "Inference is still central.",
    "details": "Inference problems (where you update one random variable based on the observation of another) are a cornerstone of the course. Many problems can start with inference and then build on top of it. This PEP question is a great example of what you should be able to do. While studying inference, it would be helpful to also practice the law of total probability (aka marginalization). ",
  },
  {
    "task": "Central Limit Theorem will show up.",
    "details": "It is a powerful tool and it will show up on the final. You have seen it in many forms, such as the source for error bars, and the basis for solving problems involving the sum of random variables. Make sure you know when it applies! Are the variables really IID? Are there more than 20 added together?",
  },
  {
    "task": "Logistic Regression problems offer a twist.",
    "details": "See the logistic regression problems from the last 5 years (in the resources from the TA section). Most logistic regression problems require you to take what we have learned, and apply it in a slightly different way. Perhaps you will solve a slightly different task. Perhaps you will have a slightly different model. The point of these 'twist' problems is to get you to focus on the underlying concepts, rather than memorizing a specific implementation.",
  },
  {
    "task": "Information Theory is new to CS109!",
    "details": "This is the first quarter we have covered Information Theory (eg Entropy). That means that there will be fewer practice problems for you! There are two main flavors of entropy questions that we have seen so far: ones where you just need to compute the entropy of a random variable, and ones where you want to use expected entropy to make a decision (for example in Think of an Animal, and the hard entropy question on the PEP handout).",
  },
  {
    "task": "Bootstrapping",
    "details": "Bootstrapping problems often involve code. Make sure to know the general bootstraping algorithm, as well as what a p-value is",
  },
  {
    "task": "Law of Total Expectation and Law of Total Probability",
    "details": "The Law of Total Expectation (which uses conditional expectation) is sometimes the only way to solve an expectation problem! It shows up a lot for algorithmic analysis questions. Make sure you know how to use it. It is related to the Law of Total Probability, which is another really important tool.",
  },
  {
    "task": "Properties of Expectation",
    "details": "Know them.",
  },
  {
    "task": "Beta Distribution",
    "details": "It is both a very helpful new random variable type, as well as an example of the sort of interesting inference problems you might have to think about.",
  },
  {
    "task": "Convolution",
    "details": "It is worth knowing the strategy behind the general discrete case. We don't emphasize the continuous case (which is similar to the discrete case, just with integrals). You should certainly know the special cases (eg sum of two Gaussians, Poissons, etc).",
  },
  {
    "task": "(From Midterm) Random Variables are central.",
    "details": "Random Variables tightly build off core probability and often are a great place to practice digesting word problems. Once you get the hang of them, you will notice there are a lot of similarities between these types of problem. Mastering problems with random variables is incredibly high bang for your buck.",
  },
  {
    "task": "(From Midterm) Probability Core is necessary foundation.",
    "details": "Your main foundation in this class is the material from lecture 4 and 5: Independence, Mutual Exclusion, Bayes, Law of Total Probability etc. You need to know this material inside and out. If you don't, you will have a lot of trouble with the rest of the midterm content.",
  },
  {
    "task": "Counting has few dependencies.",
    "details": "Counting may show up on the final as a subpart of a problem, but it won't be a main focus.",
  },
]
