

import { useComponentSize, useWindowSize } from 'react-use-size';
import styled from 'styled-components';

export const PublicLayout = ({
  mainColumn,
  singleColumn,
  rightColumn = null,
}) => {

  const windowSize = useWindowSize();

  const tooNarrow = windowSize.width < 1075

  if (tooNarrow) return <SingleColumn>{singleColumn}</SingleColumn>


  return (
    <SplashContainer id="splash-container-broad">
      <SplashMainColContainer>
        <SplashMainCol>
          {mainColumn}
        </SplashMainCol>
      </SplashMainColContainer>
      <RightCol rightColumn={rightColumn} />
    </SplashContainer>
  );
};

const RightCol = ({ rightColumn }) => {
  const size = useComponentSize()

  return <SplashRightCol style={calcRightColStyle(size)}>
    <div className='col' ref={size.ref}>
      {rightColumn}
    </div>
  </SplashRightCol>
}

const calcRightColStyle = (size) => {
  return {
    height: size.height,

    top: `min(10px, 100% - ${size.height}px)`,

  }
}

const SplashRightCol = styled.div`
  width: 200px;
  min-width: 200px;
  margin-right: $splash-margin;
  margin-left: 20px;
  margin-top: 0px;
  paddingRight: '8px',
  position: 'sticky',
  `

const SplashContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const SplashMainColContainer = styled.div`
  flex-grow: 2;
  display: flex;
  justify-content: center;
  `

const SplashMainCol = styled.div`
  max-width: 750px;
  min-width: 750px;
  margin-left: 10px;
  `

const SingleColumn = styled.div`
  margin-top:10px;
  padding-left: 10px;
  padding-right: 10px
 `