import React, { useState, useEffect, useContext } from "react";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { CourseSidebar } from "./sidebar/CourseSidebar";
import { FaBars } from 'react-icons/fa';
import { Outlet } from "react-router-dom"
import { useLocation } from 'react-router-dom';

import { CourseNavbar } from "./CourseNavbar";
import { Loading } from "components/general/loading/Loading";
import styled from "styled-components";
import { RoadmapProvider } from "contexts/RoadmapContext";


/**
 * This is the main wrapper for the course! All subpages will
 * go through this wrapper. This is helpful for several reasons:
 * 1. We can set up the sidebar in just one place
 * 2. The sidebar doesn't re-render when you navigate
 * 3. We can load data that will persist between page changes.
 */

const DESKTOP_BREAKPOINT = 1200;
const SINGLE_COL_BREAKPOINT = 1075;
const SINGLE_COL_COLLAPSE_BREAKPOINT = 800;
const MOBILE_BREAKPOINT = 575;
export const COURSE_MAX_WIDTH = 1700

// later this will be in the server
// export const APPROVED_COURSES = {
//   cip3: Role.STUDENT,
//   test: '',
//   spr23: ''
// }

export const PublicCoursePage = (props) => {
  return (
      
    <CoursePageWithContext {...props} />
  )
};

const CoursePageWithContext = ({ }) => {
  return (
    <RoadmapProvider courseId={"public"}>
      <CourseView />
      </RoadmapProvider>
  );
};

const CourseView = () => {
  const location = useLocation()
  // is this a focus page?
  const isFocusPage = checkFocusPage(location.pathname)
  if (isFocusPage) {
    return <Outlet />
  }


  return <><CourseNavbar />

    <div className="d-flex flex-1 justify-content-center" style={{ width: '100vw', backgroundColor: '#f9f9f9' }}>
      <div
        className="flex-1 w-100"
        style={{ maxWidth: COURSE_MAX_WIDTH, backgroundColor: 'white' }}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: 'calc(100vh - 46px)',
          overflow: 'hidden',
        }}>


          <CourseContainer />

        </div>
      </div>
    </div>
  </>
}

const CourseContainer = () => {
  
  return (
    <div style={{ display: 'flex', height: '100%', width: '100%', flexGrow: 2, overflow: 'auto' }}>
      <CourseSidebar />
      <CourseContentContainer>
        <Outlet />
      </CourseContentContainer>
    </div>
  );
};



const checkFocusPage = (pathname) => {
  // This means that the sidebar and navbar are not shown

  const pathSegments = pathname.split('/').filter(segment => segment !== '');

  const subPage = pathSegments[1]
  const nSegments = pathSegments.length
  if(subPage === "ide"){
    return true
  }
  if(subPage === "join"){
    return true
  }
  if(subPage === "applyteach"){
    return true
  }
  if(subPage === 'learn' && nSegments >= 3){
    return true
  }
  return false
}

const CourseContentContainer = styled.main`
width: 100%;
overflow-y: auto;
`