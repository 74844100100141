export const ROADMAP = {
  COMPLETION_LINK: {
      key: "Self Check",
      title: "Self Check Link"
  },
  ASSIGNMENT: {
      key: "Assignment",
      title: "Assignment"
  },
  LESSON: {
      key: "Lesson",
      title: "Lesson"
  },
  SECTION: {
      key: "Section",
      title: "Section"
  },
  CREATE_ASSIGNMENT: {
      key: "Create Assignment",
      title: "Create Assignment"
  },
  UNTIED_LINK: {
      key: "Link",
      title: "Link"
  }

}

export const ROADMAP_CHOICES = Object.values(ROADMAP);
export const assnTypes = ["Karel", "Console", "Graphics"];