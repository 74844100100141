import { TimeContext } from "contexts/TimeContext";
import { useContext, useEffect, useState } from "react";
import { FaClock, FaMapMarker, FaTrash, FaUser } from "react-icons/fa";
import Swal from "sweetalert2";

export const SelectedSlot = ({ selectedId, slotsData, handleSlotSelection, lengthMins }) => {

  const {getServerTimeMs, serverTimeLoaded} = useContext(TimeContext);
  const [isMeetingSoon, setIsMeetingSoon] = useState(false);


  const slotData = slotsData[selectedId];
  
  console.log(isMeetingSoon)


  useEffect(() => {
    const checkMeetingTime = () => {
      console.log("Checking meeting time");
      if(!serverTimeLoaded) {
        return;
      }
      const serverTimeMs = getServerTimeMs();
      const meetingTimeMs = parseMeetingTimeMs(slotData, serverTimeMs);
      // Check if the meeting is within the next hour (3600000 ms)
      const timeUntilMs = meetingTimeMs - serverTimeMs;
      const oneHourMs = 3600000;
      console.log(timeUntilMs)
      if (timeUntilMs < oneHourMs * 24) {
        setIsMeetingSoon(true);
      } else {
        setIsMeetingSoon(false);
      }
    };

    // Initial check
    checkMeetingTime();

    // Set up an interval to keep checking
    const interval = setInterval(checkMeetingTime, 1000); // Check every second

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  if (!slotsData[selectedId]) {
    return <div className="alert alert-danger" role="alert">
      The slot you signed up for no longer exists. Please contact an admin.
    </div>
  }


  const confirmDelete = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You are about to cancel your slot signup. You might not be able to get your time back.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel my booking!',
      cancelButtonText: 'No, keep my scheduled time'
    }).then((result) => {
      if (result.isConfirmed) {
        handleSlotSelection(null)
      }
    })
  }

  const location = slotData.location || "TBD"

  return <div className="alert alert-primary w-100" role="alert">
    <div className="d-flex align-items-start justify-content-between">
    <span>You are signed up for: <br/>
    <b><FaClock/> {slotData.date}, {slotData.time}</b><br/>
    
    <span><FaMapMarker/> Location: {location}</span><br/>
    {isMeetingSoon && <><span><FaUser/> PEP TA: {slotData.ta}, {slotData.email}</span><br/><br/></>}
    For a {lengthMins} minute one on one session.
    </span>
    <button
      className="btn btn-light ms-3"
      onClick={() => confirmDelete()}
    >
      <FaTrash/>{" "}
      Cancel Booking
    </button>
    </div>
  </div>
}

const parseMeetingTimeMs = (slotData, serverTimeMs) => {
  // Get the year from the server time
  const serverDate = new Date(serverTimeMs);
  const year = serverDate.getFullYear();

  // Clean up the date string by removing "(Online)" or any other prefix
  const cleanedDate = slotData.date.replace(/\(.*?\)\s*/, '');  // Remove text in parentheses
  
  // Parse the cleaned date using JavaScript's `Date.parse()` function to handle date formatting
  // Replace "Tuesday, Oct 22" with "Oct 22", as Date.parse() will fail with the day of the week
  const dateWithoutDay = cleanedDate.replace(/^\w+,\s*/, '');  // Remove day of the week

  // Create a full date string with the year from the server time
  const fullDateString = `${dateWithoutDay} ${year}`;  // E.g., "Oct 22 2024"

  // Parse this into a Date object
  const parsedDate = new Date(fullDateString);

  // If the parsed date is invalid, return NaN (optional error handling)
  if (isNaN(parsedDate.getTime())) {
    throw new Error("Invalid date format");
  }

  // Extract the time from slotData
  const time = slotData.time;

  // We need to check if it's AM or PM to convert the time correctly
  let [hours, minutes] = time.split(':');
  const period = time.includes('PM') ? 'PM' : 'AM';

  // Convert to 24-hour format for ISO 8601
  if (period === 'PM' && hours !== '12') {
    hours = parseInt(hours) + 12;
  } else if (period === 'AM' && hours === '12') {
    hours = 0;
  }

  // Construct the final ISO 8601 format and include the Pacific Time offset
  const iso8601DateString = `${year}-${String(parsedDate.getMonth() + 1).padStart(2, '0')}-${String(parsedDate.getDate()).padStart(2, '0')}T${String(hours).padStart(2, '0')}:${minutes.slice(0, 2)}:00-07:00`; // Assuming PDT (Pacific Daylight Time)

  // Parse the ISO 8601 string into a Date object
  const finalMeetingTime = new Date(iso8601DateString);

  // Return the time in milliseconds since the epoch
  return finalMeetingTime.getTime();
};