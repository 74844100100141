import { Button } from "react-bootstrap";
import styled from "styled-components";

const height = 45;
const width = 300;
const fontWeight = 600;

export const SignInButton = styled(Button)`
  height: ${height}px;
  width: ${width}px;
  text-align: left;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: ${fontWeight};
  color: #666;
  border-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4); // Slight shadow
`;

export const SignInButtonFlat = styled(Button)`
  color: #757575;
  // border-color: #757575;
  font-weight: ${fontWeight};
  box-shadow: none;
  height: ${height}px;
  width: ${width}px;
`;

export const PrimaryLogInButton = styled(Button)`
  height: ${height}px;
  width: ${width}px;
  font-weight: ${fontWeight};
`;

export const LogInLinkButton = styled(Button)`
  margin-top:-10px;
  text-decoration: none;
  font-weight: ${fontWeight};
`