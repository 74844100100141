import React, { useState, useEffect } from 'react';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import styled from 'styled-components';

// Styled components for the table and sticky header
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  thead th {
    position: sticky;
    top: 0;
    background-color: #f8f9fa;
    z-index: 1;
    padding: 10px;
    border-bottom: 2px solid #dee2e6;
    cursor: pointer;
  }
  tbody td {
    padding-top: 0px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #dee2e6;
  }
`;

const SortableTable = ({
  data,
  defaultSortKey = null,
  defaultSortDirection = 'ascending',
  customSortFunctions = {}
}) => {
  const [sortConfig, setSortConfig] = useState([]);

  // Initialize the default sort config if a defaultSortKey is provided
  useEffect(() => {
    if (defaultSortKey && data.length > 0 && data[0][defaultSortKey] !== undefined) {
      setSortConfig([{ key: defaultSortKey, direction: defaultSortDirection }]);
    }
  }, [defaultSortKey, data]);

  // Function to handle sorting
  const handleSort = (key) => {
    let newSortConfig = [...sortConfig];
    const existingSortIndex = newSortConfig.findIndex((config) => config.key === key);

    if (existingSortIndex > -1) {
      // If the column already exists in the sortConfig, toggle direction and move it to the front
      const currentDirection = newSortConfig[existingSortIndex].direction;
      const newDirection = currentDirection === 'ascending' ? 'descending' : 'ascending';
      // Remove the old config
      newSortConfig.splice(existingSortIndex, 1);
      // Add it to the front with the updated direction
      newSortConfig = [{ key, direction: newDirection }, ...newSortConfig];
    } else {
      // If it's a new sort, add it to the beginning of the sortConfig
      newSortConfig = [{ key, direction: 'ascending' }, ...newSortConfig];
    }

    setSortConfig(newSortConfig);
  };


  // Function to get sorting icon for the most recent sort
  const getSortIcon = (key) => {
    if (!sortConfig.length || sortConfig[0].key !== key) {
      return <FaSort />;
    }
    return sortConfig[0].direction === 'ascending' ? <FaSortUp /> : <FaSortDown />;
  };

  // Function to sort data based on the entire sortConfig array

  function sortFn(key, customSortFns, elem1, elem2) {
    if (customSortFns[key]) {
      const fn = customSortFns[key];
      return fn(elem1[key], elem2[key]);
    }
    if(elem1[key] === elem2[key]) {
      return 0
    }
    return elem1[key] < elem2[key] ? -1 : 1;
  }


  const sortedData = [...data].sort((a, b) => {
    for (let { key, direction } of sortConfig) {
      const sortResult = sortFn(key, customSortFunctions, a, b);
      if (sortResult < 0) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (sortResult > 0) {
        return direction === 'ascending' ? 1 : -1;
      }
    }
    return 0; // If all keys match, return 0 (no sorting)
  });

  // Dynamically generate table headers from the data
  const headers = data.length > 0 ? Object.keys(data[0]) : [];

  return (
    <StyledTable>
      <thead>
        <tr>
          {headers.map((key) => (
            <th key={key} onClick={() => handleSort(key)}>
              {key.charAt(0).toUpperCase() + key.slice(1)} {getSortIcon(key)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((item, index) => (
          <tr key={index}>
            {headers.map((key) => (
              <td key={key}>{item[key]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default SortableTable;
