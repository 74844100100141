import styled from "styled-components";
import { doc, getFirestore } from "firebase/firestore";
import { useQuarterId } from "hooks/UseQuarterId";
import { useDocumentData } from "react-firebase-hooks/firestore";

const Container = styled.div`
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: #333;
`;

const BracketsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  gap: 40px;
  padding: 10px;
  justify-content: left;
`;

const RoundContainer = styled.div`
  min-width: 300px;
  text-align: center;
  position: relative;
`;

const RoundTitle = styled.h3`
  margin-bottom: 0px;
  font-size: 1rem;
  margin-bottom: 10px;
  color: #555;
`;

const Matchup = styled.div`
  height:100px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 10px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
`;

const Song = styled.div`
  font-size: 1rem;
  color: #333;
  margin: 5px 0;
  font-weight: bold;
`;

const VS = styled.div`
  font-size: 0.9rem;
  color: #999;
  margin: 0;
`;

const SongTagTitle = styled.a`
  font-size: 1rem;
  color: #333;
  max-width: 350px;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;


export const MusicEliminationBrackets = () => {
  const qtrId = useQuarterId();
  const db = getFirestore();

  const eliminationPath = `/music/${qtrId}/public/elimination`;
  const songDataPath = `music/${qtrId}/public/songs`;

  const [songData, songsLoading] = useDocumentData(doc(db, songDataPath));
  const [eliminationData, eliminationLoading] = useDocumentData(doc(db, eliminationPath));

  if (songsLoading || eliminationLoading) {
    return <Container>Loading...</Container>;
  }

  // Sort the rounds by their numeric suffix (e.g., "round1", "round2")
  const rounds = Object.keys(eliminationData).sort((a, b) => {
    const roundA = parseInt(a.replace("round", ""), 10);
    const roundB = parseInt(b.replace("round", ""), 10);
    return roundA - roundB;
  });

  return (
    <Container>
      <BracketsContainer>
        {rounds.map((round, index) => (
          <BracketRound
            key={round}
            roundName={round}
            bracketData={eliminationData[round]}
            songData={songData}
            previousMatchups={index === 0 ? null : Object.keys(eliminationData[rounds[index - 1]])}
          />
        ))}
      </BracketsContainer>
    </Container>
  );
};

const BracketRound = ({ roundName, bracketData, songData, previousMatchups }) => {
  const bracketHeight = 100
  const bracketGap0 = 20
  const roundIndex = parseInt(roundName.replace("round", ""), 10);


  const getMarginTopHelper = (bracketIndex) => {
    if (!previousMatchups) {
      return 0; // No margin for the first round
    }


    const pairRound0Height = 2 * bracketHeight + bracketGap0
    const firstBracketMargin = (pairRound0Height - bracketHeight) / 2 +1

    if (bracketIndex === 0) {
      return firstBracketMargin
    }

    return 2 * firstBracketMargin + bracketGap0

  };

  const getMarginTop = (bracketIndex) => {
    const marginTop = getMarginTopHelper(bracketIndex)
    console.log("margin top", bracketIndex, marginTop)
    return marginTop
  }

  // Interleave the bracket keys
  const bracketKeys = Object.keys(bracketData).sort((a, b) => {
    const aNum = parseInt(a.replace("bracket", ""), 10);
    const bNum = parseInt(b.replace("bracket", ""), 10);
    return aNum - bNum; // Sort numerically first
  });

  const interleavedBracketKeys = [];
  for (let i = 0, j = bracketKeys.length - 1; i <= j; i++, j--) {
    interleavedBracketKeys.push(bracketKeys[i]); // Add the smaller index
    if (i !== j) {
      interleavedBracketKeys.push(bracketKeys[j]); // Add the larger index
    }
  }

  const bracketOrder = roundIndex == 1 ? interleavedBracketKeys : bracketKeys

  return (
    <RoundContainer>
      <RoundTitle>{roundName.toUpperCase()}</RoundTitle>
      {bracketOrder.map((bracketKey, index) => {
        const [song1Id, song2Id] = bracketData[bracketKey];
        const song1 = songData[song1Id];
        const song2 = songData[song2Id];

        return (
          <Matchup key={bracketKey} marginTop={getMarginTop(index)}>
            <SongTag songData={song1} />
            <VS>VS</VS>
            <SongTag songData={song2} />
          </Matchup>
        );
      })}
    </RoundContainer>
  );
};

const SongTag = ({ songData }) => {
  console.log("songData", songData)
  return <SongTagTitle 
    href={songData.link}
    target="_blank"
  >
      {songData.title} - {songData.artist}
  </SongTagTitle>;
}
