import { doc, getDoc, getFirestore } from "firebase/firestore";
import { usePSetId } from "hooks/UsePSetId";
import { useQuarterId } from "hooks/UseQuarterId";
import { useEffect, useState } from "react";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { ProgressWithData } from "./ProgressWithData";

export const ProgressPage = () => {

  const qtrId = useQuarterId()

  const [classData, loading] = useDocumentDataOnce(doc(getFirestore(), `class/${qtrId}/`))

  if (loading) {
    return <></>
  }

  if (!classData || !classData.students) {
    return <p>Error: no students found</p>
  }

  return <ProgressPageWithRoster
    roster={classData.students}
  />
}

const ProgressPageWithRoster = ({ roster }) => {

  const [studentProgress, setStudentProgress] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const studentIdToDataMap = parseClassData(roster)
  const qtrId = useQuarterId()
  const psetId = usePSetId()
  /*
  This is a complex data pull, that needs to be done in parallel. For each studentId
  (key in studentIdToDataMap), we need to pull the data in the document
  /users/${studentId}/${qtrId}/${psetId}
  */

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const promises = Object.keys(studentIdToDataMap).map((studentId) => {
          const docRef = doc(getFirestore(), `users/${studentId}/${qtrId}/${psetId}`);

          // Return the promise directly without awaiting
          return getDoc(docRef).then((completionDoc) => {
            if (completionDoc.exists()) {
              return { studentId, data: completionDoc.data() };
            } else {
              return { studentId, data: null }; // Handle missing documents
            }
          });
        });

        // Use Promise.all to run them in parallel
        const results = await Promise.all(promises);

        const dataMap = results.reduce((acc, { studentId, data }) => {
          acc[studentId] = data;
          return acc;
        }, {});

        setStudentProgress(dataMap);
      } catch (err) {
        setError("Failed to fetch student data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentData();
  }, [roster, qtrId, psetId]);

  if (loading) return <p>Loading student progress...</p>;
  if (error) return <p>{error}</p>;

  return <ProgressWithData
    studentProgress={studentProgress}
    studentIdToDataMap={studentIdToDataMap}
  />
}



const parseClassData = (roster) => {
  const studentIdToDataMap = {}
  for (const student of roster) {
    studentIdToDataMap[student.uid] = student
  }
  return studentIdToDataMap
}