import { getAuth } from "firebase/auth";
import { doc, getFirestore } from "firebase/firestore";
import { createContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";

const defaultData = {
  userMetaData: undefined
};

export const UserContext = createContext(defaultData);
export const UserProvider = ({children}) => {
  const user = useUser();

  const userId = user.uid
  console.log(`users/${userId}`)
  var [userMetaData, userMetaDataLoading] = useDocumentData(doc(getFirestore(),`users/${userId}`)); 
  
  return (
    <UserContext.Provider
      value={{
        userMetaData,
        userMetaDataLoading
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const useUser = () => {
  const auth = getAuth();
  const [user] = useAuthState(auth);
  return user
}