// import firebase from "firebase";
import React, { Suspense, lazy, useEffect } from "react";

import { useParams } from "react-router-dom";
import {
  useDocumentData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  query,
} from "firebase/firestore";
import { firestore, auth } from "firebaseApp.js";
import { useUser } from "contexts/UserContext";
import { useQuarterId } from "hooks/UseQuarterId";
import { PublicHome } from "public/publichome/PublicHome";

export const Home = (props) => {
  const user = useUser()

  let userName = user?.displayName

  const qtrId = useQuarterId()


  var [classData, classDataLoading, classDataLoadingErr] = useDocumentData(
    doc(getFirestore(), `class/${qtrId}/public/classData`)
  );

  if (classDataLoading) {
    return <></>
  }

  if(classDataLoadingErr) {
    return <>Error loading class data</>
  }

  if(qtrId === 'public') {
    return <>Error public class should be caught by router</>
  }

  return <HomeWithData
    userName={userName}
    classData={classData}
    qtrId={qtrId}
  />
}

const HomeWithData = (props) => {
  console.log(props.classData)
  return <>
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column align-items-center">
            <h1>CS109 Problem Set App</h1>
            <h2> {props.classData.title}</h2>
            <h2>Welcome {props.userName} <button
              className="btn btn-primary"
              onClick={() => auth().signOut()}
            >Logout</button></h2>

            <hr style={{ width: '500px' }} />


            <div>
              {props.classData.psets.map(function (pset, i) {
                return <a
                  key={i}
                  className="btn btn-primary"
                  style={{ marginRight: '5px' }}
                  href={`/${props.qtrId}/${pset.psetId}/`}
                >{pset.title}</a>
              })}

              <br /><br />
            </div>
          </div></div></div></div>
  </>
}
