import React, { useState } from 'react';
import {Navigate } from 'react-router-dom'
import {
    useDocumentDataOnce,
  } from "react-firebase-hooks/firestore";
import {
  useParams
} from "react-router-dom";

export const GradingHome = (props) => {

    // get the first question in the pset and redirect to it

    let { qtrId, psetId } = useParams();

    return <Navigate 
        to={`/grading/${qtrId}/${psetId}/splash`}
    />
  }