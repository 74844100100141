import React, { useState, useEffect } from 'react';
import { CodeOutputSplit  } from '../../components/questionParts/CodeOutputSplit.js';
import { CodeAgent } from '../../components/questionParts/CodeAgent.js';
import {CodeCanvasSplit} from '../../components/questionParts/CodeCanvasSplit.js'
import { CodeGraphSplit } from '../../components/questionParts/CodeGraphSplit.js';
import { RichTextEditor } from '../../components/richText/RichTextEditor.js';

export const GradingAnswerExplanation = (props) => {
    if(props.questionType.includes('agent')) {
      return <>
        <CodeAgent {...props} 
          firebaseDocPath = {props.explanationPath}
          submitAnswer= {(e) =>{}}
        />
      </>
    }



    if(props.questionType.includes('graph')) {
      return <>
        <CodeGraphSplit {...props} 
          firebaseDocPath = {props.explanationPath}
          submitAnswer= {(e) =>{}}
        />
      </>
    }

    if(props.questionType.includes('canvas')) {
      return <>
        <CodeCanvasSplit {...props} 
          firebaseDocPath = {props.explanationPath}
          submitAnswer= {(e) =>{}}
        />
      </>
    }
  
    if(props.questionType.includes('code')) {
      return <>
        <CodeOutputSplit {...props} 
          firebaseDocPath = {props.explanationPath}
          submitAnswer= {(e) =>{}}
        />
      </>
    }
    if(props.questionType.includes('none')) {
      return <></>
    }

    return <>
      <RichTextEditor {...props}
        firebaseDocPath = {props.explanationPath}
        collaborative={props.collaborative}
        editable={props.editable}
        contentKey={'content'}
      />
    </>
  }