export const studyPlanTodos = [
  {
    "task": "Do at least two practice exams completely.",
    "subtasks": [
      {
        "task": "Take practice exams in realistic conditions: with pencil and paper, timed, uninterrupted, and without peeking at solutions. Handwritten tests with no computer feel very different from the pset app."
      },
      {
        "task": "Use practice exams to reflect on how deep your understanding of concepts is to decide what to prioritize more study on. We recommend Fall 2022 as the ideal practice exam!"
      }
    ]
  },
  {
    "task": "Go for \"quality over quantity\" of practice problems while studying.",
    "details": "Instead of feeling pressure to get through as many problems as possible, take time to deeply analyze why each problem's solution works before moving on."
  },
  {
    "task": "Practice problem-solving as a general skill.",
    "subtasks": [
      {
        "task": "Draw analogies from new problems to old, search for themes and patterns amongst problems, and practice recognizing how high-level concepts are applied in math or code. Recognize the repeating patterns in the problems (while each problem might feel unique, it is often a variation on a template)."
      },
      {
        "task": "Practice explicitly naming the steps to solve every problem you review, referring back to general concepts and always noting the \"why\" for each step. For example: each time you multiply, decide: are you using the step rule of counting, or finding probability of \"and\" with the chain rule? Seeing patterns across problems is easier once you notice yourself using similar language to explain solutions."
      },
      {
        "task": "To improve at translating word problems into math, try writing new problems yourself (like PSet 1's Create-A-Problem). You'll start to recognize how information gets encoded in words and what information a problem-writer has to give you to make a problem solvable. Get feedback on your problems from other students or a TA through office hours or Ed."
      },
      {
        "task": "Study by hunting for contrasts: review groups of similar problems and articulate what makes each similar vs. different from the rest."
      },
      {
        "task": "Debate alternative solutions to problems, with yourself or others For each practice problem, challenge yourself to imagine both another way to solve it and an approach that wouldn’t have worked (and why)."
      },
      {
        "task": "For each problem you review, imagine different twists: if the problem wording said Y instead of X, how would the solution change? Can you edit only a few words to significantly change how a problem should be solved?"
      }
    ]
  },
  {
    "task": "Make your cheat sheet early.",
    "details": "Use it for practice exams, and you'll notice anything missing before the real deal. Brain-dump formulas and \"memorization\" details into your notes so you're free to focus more on conceptual mastery."
  },
  {
    "task": "Study with others at least once before the midterm!",
    "subtasks": [
      {
        "task": "Explain your thought process to others and learn from hearing other students explain theirs. Find fellow students and TAs to chat with at office hours or on Ed."
      },
      {
        "task": "Studying alone can mean feeling the stress solo; study partners can help lighten that load. Even just studying at office hours around other students is beneficial."
      }
    ]
  }
]
