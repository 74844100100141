import styled from "styled-components"
import { SongLink } from "./SongLink"
import { Handout } from "components/handout/Handout"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { useUser } from "contexts/UserContext"
import axios from "axios"
import { useQuarterId } from "hooks/UseQuarterId"
import Swal from "sweetalert2"
import { dateToWeekIndex } from "../MusicUtil"
import { Quote, SongTitle } from "./SongStyles"

export const RateSongs = ({ currWeekVotes, songData, editable = true }) => {

  console.log('currWeekVotes', currWeekVotes)
  console.log('songData', songData)

  // get the songData for the songs in currWeekVotes
  const songs = []
  for (const songId in currWeekVotes) {
    const song = songData[songId]
    if (song) {
      songs.push(song)
    }
  }

  return <Handout
    element={<RateSongsInner currWeekVotes={currWeekVotes} songs={songs} editable={editable}/>}
  />
}





const RateSongsInner = ({ currWeekVotes, songs, editable}) => {
  const user = useUser();
  const [ratings, setRatings] = useState(currWeekVotes);
  const qtrId = useQuarterId()
  // 'submitting', 'submitted', 'notSubmitted'
  const [submitStatus, setSubmitStatus] = useState(editable ? 'notSubmitted' : 'submitted');

  const setSongRating = (songId, newRating) => {
    // update the ratings object
    setRatings(oldRatings => {
      return {
        ...oldRatings,
        [songId]: newRating
      }
    })
  }

  const nRatings = Object.values(ratings).filter(v => v !== undefined).length
  const nTodo = songs.length
  const isComplete = nRatings === nTodo;

  const onSubmit = async () => {
    setSubmitStatus('submitting');
    await submitRatings(user, qtrId, ratings)
    setSubmitStatus('submitted');
  }

  return (
    <div>
      <RatingTitle/>
      <hr/>
      <SubmitButton onSubmit={onSubmit} submitStatus={submitStatus}isComplete = {isComplete}/>
      
      <SongsOuter>
        {songs.map(song => {
          return <RateSong 
            voteIndex={songs.indexOf(song) + 1}
            key={song.id} 
            song={song} 
            rating={ratings[song.id]}
            setRating={(rating) => {
              setSongRating(song.id, rating)
            }}
            submitStatus={submitStatus}
          />
        })}
      </SongsOuter>
      
    </div>
  )

}

const SubmitButton = ({onSubmit, submitStatus, isComplete}) => {


  if (submitStatus === 'submitted') {
    return <div className="alert alert-primary">Your ratings have been submitted. Come back next week for more!</div>
  }
  if (submitStatus === 'submitting') {
    return <Button variant="primary" disabled>Submitting...</Button>
  }
  return <Button disabled={!isComplete} onClick={onSubmit} variant="primary">Submit Ratings</Button>
}

export const RatingTitle = () => {
  const user = useUser()
  const name = user.displayName;
  const weekIndex = dateToWeekIndex(new Date()) + 1;
  return <h1>{name} Songs Week {weekIndex}</h1>
}

const submitRatings = async (user, qtrId, ratings) => {
  const token = await user.getIdToken(true)
  let URL = `https://us-central1-guia-cs109psets.cloudfunctions.net/submitSongRatings`;
  const response = await axios.post(URL, {
    data: {
      token: token,
      qtrId: qtrId,
      votes: ratings
    }
  })

  Swal.fire({
    title: 'Ratings Submitted!',
    text: 'Thanks for rating the songs! See you next week.',
    icon: 'success',
    confirmButtonText: 'Cool'
  })
  return response.data.result
}

const RateSong = ({ song, rating, voteIndex, setRating, submitStatus}) => {
  const editable = submitStatus == 'notSubmitted';
  // Rate songs on a 1-5 scale
  return (
    <SongRow>
      <SongTitle>{voteIndex}) {song.title} - {song.artist}</SongTitle>
      <SongLink 
        songLink = {song.link}
        artist = {song.artist}
        title = {song.title}
      />
      <Quote>{song.reason}</Quote>
      
      <LikertButtons 
        selectedRating={rating}
        setSelectedRating={setRating}
        editable={editable}
      />
  
    </SongRow>
  )
}


const LikertButtons = ({selectedRating, setSelectedRating, editable}) => {

  const handleClick = (rating) => {
    setSelectedRating(rating);
  };

  const buttonLabels = {
    1: '1: No Thanks',
    2: '2: Meh',
    3: '3: Fine',
    4: '4: Nice',
    5: '5: Awesome'
  }

  return (
    <div className="btn-group" role="group" aria-label="Likert scale">
      {
        Object.entries(buttonLabels).map(([rating, label]) => {
          console.log('selectedRating', rating, selectedRating)
          const ratingInt = parseInt(rating)
          return (
            <Button 
              key={rating}
              type="button" 
              className={`btn ${selectedRating == rating ? 'btn-primary' : 'btn-light'}`} 
              onClick={() => handleClick(ratingInt)}
              disabled={!editable}
            >
              {label}
            </Button>
          )
        })
      }
    </div>
  );
};

const SongRow = styled.div`
  display: flex;
  flex-direction: column;
`

const SongsOuter = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  gap: 25px;
`