import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Loading } from '../loading/Loading.js';
import { useParams } from 'react-router-dom';
import { LoginPublic } from './LoginPublic.js'
import { useUser } from 'contexts/UserContext.js';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

export const Login = () => {
  // for now the pset app is only for Stanford students
  return LoginStanford()
};

const LoginStanford = () => {

  /**
  TODO: Temporary login fix.
  Here I check if the user is logging in from localhost.
  Why is this necessary? I am not exactly sure. On June 24, 2024
  Firebase changed how login works. It seems fine on deployment,
  but on localhost, the login button does not work (it infinite loops).
  To get around this issue I have to set the signInFlow to 'popup' when
  the user is on localhost. This is a temporary fix.
  */
 
  const isLocalhost = window.location.hostname === "localhost";


  const uiConfig = {
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          hd: 'stanford.edu',
          auth_type: 'reauthenticate',
          prompt: 'select_account'
        }
      }
    ],
    signInFlow: isLocalhost ? 'popup' : 'redirect',
    immediateFederatedRedirect: true
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
      />
    </div>
  );
}