import SortableTable from "components/tables/SortableTable"
import { usePSetId } from "hooks/UsePSetId"
import { useQuarterId } from "hooks/UseQuarterId"
import { Link } from "react-router-dom"

export const ProgressWithData = ({studentProgress, studentIdToDataMap}) => {

  const psetId = usePSetId()
  const qtrId = useQuarterId()
  const tableData = makeTableData(studentProgress, studentIdToDataMap, psetId, qtrId)

  

  return <>
    <h3>Completion Stats: {psetId}</h3>
    <CompletionStats
      studentProgress={studentProgress}
    />
    <SortableTable
      data={tableData}
      defaultSortKey={"nCorrects"}
      defaultSortDirection={"descending"}
      />
  </>
}

const CompletionStats = ({studentProgress}) => {
  const correctList = []
  for(const studentId in studentProgress) {
    const nCorrect = countCorrects(studentProgress[studentId])
    correctList.push(nCorrect)
  }

  const nStudents = correctList.length
  const maxCorrects = Math.max(...correctList)
  const muCorrects = correctList.reduce((acc, n) => acc + n, 0) / correctList.length
  const stdCorrects = Math.sqrt(correctList.reduce((acc, n) => acc + (n - muCorrects) ** 2, 0) / correctList.length)
  const nGreaterThanZero = correctList.filter(n => n > 0).length 
  return <>
    N Students: {nStudents}<br/>
    N Started: {nGreaterThanZero}<br/>
    Max corrects: {maxCorrects}<br/>
    Mean corrects: {muCorrects.toFixed(3)}<br/>
    Std corrects: {stdCorrects.toFixed(3)}<br/>
    <div style={{height: "20px"}}></div>
  </>
}

const makeTableData = (studentProgress, studentIdToDataMap, psetId, qtrId) => {
  const tableData = []
  for (const studentId in studentIdToDataMap) {
    const progress = studentProgress[studentId]
    const studentInfo = studentIdToDataMap[studentId]
    const nCorrects = countCorrects(progress)
    console.log(progress)

    const workLink = `/${qtrId}/${psetId}/splash/${studentId}`

    const row = {
      nCorrects: nCorrects,
      name: studentInfo.name,
      email:studentInfo.email,
      studentId: <Link to={workLink}>{studentId}</Link>,
    }
    tableData.push(row)
  }
  return tableData

}

const countCorrects = (progress) => {
  if(!progress || !progress.corrects) return 0
  const corrects = progress.corrects

  // corrects is a dictionary from qId -> boolean
  const nCorrects = Object.values(corrects).reduce((acc, correct) => {
    return correct ? acc + 1 : acc
  }, 0)

  return nCorrects
}