import { doc, getFirestore, setDoc } from 'firebase/firestore';
import React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid black;
  cursor: pointer;
`;

const UnselectedCircle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40%;
  border: 2px solid #777;
  cursor: pointer;
`;

const SelectedNACircle = styled(Circle)`
display: flex;
justify-content: center;
align-items: center;
font-size: 14px;
`

const UnselectedNACircle = styled(UnselectedCircle)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #777;
`


const Container = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 20px;
`;

const NA_KEY = 'n/a';

const DiscreteRadioButtons = ({ dbDocPath, dbDocData, docKey }) => {
  const selectedButton = dbDocData?.[docKey]; // Reading the selected button from Firestore data
  const docRef = doc(getFirestore(), dbDocPath);

  const handleClick = (index) => {
    if (selectedButton === index) {
      setDoc(docRef, { [docKey]: null }, { merge: true });
    } else {
      // index is 0 for N/A, otherwise it's 1-5
      setDoc(docRef, { [docKey]: index }, { merge: true });
    }
  };

  return (
    <Container>
      {/* N/A button (value of 'na') */}
      <NAButton
        selectedButton={selectedButton}
        handleClick={handleClick}
      />

      {/* Rest of the options */}
      {Array.from({ length: 5 }).map((_, index) => {
        const actualIndex = index + 1; // Shift index by 1 for options 1-5
        const hasSelection = selectedButton !== null;
        const isSelected = selectedButton === actualIndex;

        if (isSelected) {
          return <Circle
            key={actualIndex}
            onClick={() => handleClick(actualIndex)}
          />;
        }
        return <UnselectedCircle
          key={actualIndex}
          onClick={() => handleClick(actualIndex)}
        />;


      })}
    </Container>
  );
};

const NAButton = ({ selectedButton, handleClick }) => {
  const hasSelection = selectedButton !== null;
  const isSelected = selectedButton === NA_KEY;

  if (isSelected) {
    return <SelectedNACircle onClick={() => handleClick(NA_KEY)}>
      S
    </SelectedNACircle>
  }


  return <UnselectedNACircle onClick={() => handleClick(NA_KEY)}>
    S
  </UnselectedNACircle>

}

export default DiscreteRadioButtons;
