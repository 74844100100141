import { Handout } from "components/handout/Handout"
import SortableTable from "components/tables/SortableTable"
import { doc, getFirestore } from "firebase/firestore"
import { useQuarterId } from "hooks/UseQuarterId"
import { useDocumentData } from "react-firebase-hooks/firestore"

import { erf } from 'mathjs';
import VoteHistogram from "./components/VoteHistogram"
import styled from "styled-components"
import { SampleMeanPDF } from "./components/SampleMeanPDF"

const minVotesForAnalysis = 5
const minVotesForProbBest = 4

export const MusicRatingsList = () => {
  const db = getFirestore()
  const qtrId = useQuarterId()

  const votePath = `music/${qtrId}/public/allVotes`
  const songDataPath = `music/${qtrId}/public/songs`

  const [votes, votesLoading] = useDocumentData(doc(db, votePath))
  const [songData, songsLoading] = useDocumentData(doc(db, songDataPath))

  // ref = db.document(f'music/{constants.qtr_id}/public/allVotes')
  // votes = ref.get().to_dict()

  if(votesLoading || songsLoading) {
    return <></>
  }

  const voteMap = makeVoteMap(votes)

  const data = []

  for (let songId in voteMap) {
    const intSongId = parseInt(songId)
    const scores = voteMap[songId]
    let avgScore = scores.reduce((a, b) => a + b, 0) / scores.length


    // calculate standard error
    let standardError = calcStandardErrorOfMean(scores)
    standardError = Math.round(standardError * 100) / 100

    // round avgScore to 3 decimal places
    avgScore = Math.round(avgScore * 100) / 100
    const songInfo = songData[songId]
    const artist = songInfo?.artist
    const title = songInfo?.title
    data.push({
      '#': 0,
      // 'Sample Mean': `N(μ = ${avgScore}, σ = ${standardError})`,
      song: <SongName artist={artist} title={title} />,
      'Sample Mean PDF': <SampleMeanPDF mean={avgScore} std={standardError} />,
      votes: <VoteHistogram scores={scores}/> ,
      numVotes: scores.length,
      avgScore,
      'SampleMean': avgScore,
      'SEOM': standardError,
      songId:intSongId
    })
  }

  // add the probability that the song is in the top 16
  addTopKProbability(data, 16, 'Pr(Top16)')
  addTopProbability(data, 'Pr(Best)')


  // sort songs by their average score
  data.sort((a, b) => {
    const bScore = b["Pr(Best)"]
    const aScore = a["Pr(Best)"]

    // if they have the same score, sort by avgScore
    // if (bScore === aScore) {
      return b.avgScore - a.avgScore
    // }

    return bScore - aScore
  }) 
  
  // add a rank to each song 
  data.forEach((song, index) => song['#'] = index + 1)


  // remove the songIs
  // data.forEach(song => delete song.songId)
  data.forEach(song => delete song.avgScore)
  // data.forEach(song => delete song.SEOM)



  return <SortableTable
    data={data}
    defaultSortKey={'rank'}
  />
}



const addTopProbability = (data, key) => {
  const rawProbabilities = {};

  // get the song with the highest average scores
  // and record its avg and SEOM
  let maxAvg = 0
  let maxSEOM = 0

  for (let song of data) {
    if(song.avgScore > maxAvg) {
      maxAvg = song.avgScore
      maxSEOM = song.SEOM
    }
  }


  // Step 1: Calculate raw probabilities
  for (let songA of data) {
    const avgA = songA.avgScore;
    const seA = songA.SEOM;
    const nVotesA = songA.numVotes;

    if (nVotesA < minVotesForProbBest) {
      rawProbabilities[songA.songId] = 0;
      songA[key] = 0
      continue;
    }

    const diffMu = avgA - maxAvg;
    const diffStd = Math.sqrt(Math.pow(seA, 2) + Math.pow(maxSEOM, 2));


    const pr = 1 - gaussianCDF(0, diffMu, diffStd);


    rawProbabilities[songA.songId] = pr

    songA[key] = pr.toFixed(3)
  }

  

  return data;
}


const SongName = ({ artist, title }) => {
  return <SongNameOuter>{title} - {artist}</SongNameOuter>
}


const addTopKProbability = (data, topK = 16, key) => {
  // sample many times and see how many times each song is in the top k
  const numSamples = 100000

  const top16Pr = {}

  for(let i = 0; i < numSamples; i++) {

    // for each song, sample its average score
    const sampleMap = {}
    for (let song of data) {
      const nVotes = song.numVotes
      if(nVotes < minVotesForAnalysis){
        continue
      }

      const avgScore = song.avgScore
      const standardError = song.SEOM
      // sample from the gaussian
      const sampleScore = gaussianRandom(avgScore, standardError)
      sampleMap[song.songId] = sampleScore
    }

    const top16 = Object.entries(sampleMap)
      .sort((a, b) => b[1] - a[1])  // Sort by score in descending order
      .slice(0, topK)                 // Take the topK songs
      .map(([songId, score]) => ({ songId, score })); // Map to desired format

    for(let topSong of top16) {
      if(!top16Pr[topSong.songId]) {
        top16Pr[topSong.songId] = 0
      }
      top16Pr[topSong.songId] += 1 / numSamples
    }

    
  }

  for(let song of data) {
    let rawPr = 0
    if(top16Pr[song.songId]) {
      rawPr = top16Pr[song.songId]
    }

    const pr = rawPr.toFixed(3);
    song[key] = pr
  }


  return top16Pr


}

const makeVoteMap = (votes) => {
  // equivalence map
  const equivalenceMap = {
    71:137,
    14:137,
    144:15,
    79:38
  }


  const voteMap = {}
  for (let voteId in votes) {
    const [songId, userId] = voteId.split('.')

    let equivalentId = songId
    if (equivalenceMap[songId]) {
      equivalentId = equivalenceMap[songId]
    }

    const voteData = votes[voteId]
    const score = voteData?.score
    if (score) {
      if (!voteMap[equivalentId]) {
        // every song gets a single vote of 3 injected
        voteMap[equivalentId] = [3]
      }
      voteMap[equivalentId].push(score)
    }
  }
  return voteMap
}

function calcStandardErrorOfMean(scores) {
  if (scores.length < 2) return 0; // At least 2 scores needed for sample standard deviation

  const mean = scores.reduce((sum, score) => sum + score, 0) / scores.length;
  const variance = scores.reduce((sum, score) => sum + Math.pow(score - mean, 2), 0) / (scores.length - 1);
  const sampleStandardDeviation = Math.sqrt(variance);
  
  return sampleStandardDeviation / Math.sqrt(scores.length);
}

// Standard Normal variate using Box-Muller transform.
function gaussianRandom(mean=0, stdev=1) {
  const u = 1 - Math.random(); // Converting [0,1) to (0,1]
  const v = Math.random();
  const z = Math.sqrt( -2.0 * Math.log( u ) ) * Math.cos( 2.0 * Math.PI * v );
  // Transform to the desired mean and standard deviation:
  return z * stdev + mean;
}

const gaussianCDF = (x, mean = 0, stdev = 1) => {
  const z = (x - mean) / stdev;
  return 0.5 * (1 + erf(z / Math.sqrt(2)));
};

const SongNameOuter = styled.div`
  white-space: nowrap;
  overflow-x: auto; // Enable horizontal scrolling
  text-overflow: auto;
  max-width: 400px;

  &::-webkit-scrollbar {
    height: 0px; // Optional: make the scrollbar smaller
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; // Optional: customize scrollbar color
    border-radius: 4px;
  }

  &:hover {
    cursor: pointer; // Optional: change cursor on hover to indicate scrollable area
  }
`;

