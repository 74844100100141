import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  FaBars,
  FaBell,
  FaCog,
  FaFlag,
  FaSeedling,
  FaQuestionCircle,
  FaToolbox,
} from "react-icons/fa";
import styled from "styled-components";
import { Nav, Navbar as BootstrapNavbar, NavDropdown } from "react-bootstrap";
// import {LinkContainer} from 'react-router-bootstrap'
import { Link, useLocation } from "react-router-dom";
import { signOut, getAuth } from "firebase/auth";
import { useWindowSize } from "components/position/useWindowSize";
import { useCourseId } from "hooks/UseUrlParams";
import { COURSE_MAX_WIDTH } from "./PublicCoursePage";



const titleMap = {
  studenthome: "Home Page for Students",
  section: "Your Section",
  teacherslounge: "Teacher's Lounge",
  training: "Teacher Training Center",
  code: " ",
  practice: " ",
  create: " ",
  monitorsections: "Monitor Sections",
  learn: "Learning Center",
  transcriptfeedback: "Transcript Feedback",
  forum: "Classwide Forum",
  stories: "Pathways into Coding",
  events: "Events",
  aboutcourse: "About Code in Place",
  home: "Home Page for Teachers",
};

export const CourseNavbar = (props) => {

  return (
    <Navbar>
    </Navbar>
  );
};


const Navbar = ({ children }) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 768
  const courseId = useCourseId();
  return (
    <NavbarWrapper bg="dark" variant="dark">
      <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BootstrapNavbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-center"
      >
        <div
          className="d-flex justify-content-between align-items-center w-100"
          style={{ maxWidth: COURSE_MAX_WIDTH }}
        >
          {/* left size */}
          <Nav>
            <BootstrapNavbar.Brand>
              <BrandCS109 to={`/public`}>
                CS109
              </BrandCS109>
            </BootstrapNavbar.Brand>
          </Nav>

          <CenterTitle />

          {/* right side */}
          {children && (
            <NavIcons
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                // TODO: @TJ can you make this a ref so that this width matches left size -- Still need to do this maybe... quick fix implemented
                minWidth: isMobile ? "0px": "260px",
              }}
            >
              {children}
            </NavIcons>
          )}
        </div>
      </BootstrapNavbar.Collapse>
    </NavbarWrapper>
  );
};

const CenterTitle = () => {
  const location = useLocation();
  
  // dont show this if the page is too small
  const { width } = useWindowSize();
  if (width < 800) {
    return null;
  }
  const currSubpage = location.pathname.split("/")[2];

  let title = getTitle(currSubpage);
  

  return <Nav style={centerTitleStyle}>{title}</Nav>;
};

function getTitle(subpage) {
  return titleMap[subpage] || toTitleCase(subpage);
}

const NavbarWrapper = styled(BootstrapNavbar)`
  padding-left: 15px;
  padding-right: 15px;
  z-index: 1;
  height: 45px;
`;

// rgb(208, 224, 255)
// rgb(89, 64, 203) nice purple
// #353a3f

const NavIcons = styled(Nav)`
  display: flex;
  alignitems: center;
`;

const BrandCS109 = styled(Link)`
  color: white;
  // no underline
  text-decoration: none;
  font-weight: 800;
`

const centerTitleStyle = {
  color: "#D4D1D1",
  fontWeight: 600,
  fontSize: 18,
};

function toTitleCase(str) {
  if (!str) {
    return "";
  }
  var words = str.toLowerCase().split(" ");
  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(" ");
}

const UserIcon = ({ photoURL, name }) => {
  if (photoURL) {
    return (
      <img
        src={photoURL}
        width="30"
        height="30"
        className="rounded-circle"
        style={{ objectFit: "cover" }}
      />
    );
  }

  // draw a salmon circle with the letter C in the middle
  let initial = "";
  if (name) {
    if (name.length > 0) {
      initial = name[0];
    }
  }
  if (!initial) {
    initial = "S";
  }

  return (
    <div style={{ display: "inline-block" }}>
      <div className="rounded-circle" style={userIconStyle}>
        {initial}
      </div>
    </div>
  );
};

const userIconStyle = {
  width: "30px",
  height: "30px",
  // display: 'inline-block',
  color: "white",
  borderRadius: "50%",
  backgroundColor: "salmon",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "24px",
  position: "relative",
};
