import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend);

export const SampleMeanPDF = ({ mean, std }) => {
  // Generate x values from 1 to 5 with a fine step size for smooth curve
  const xValues = Array.from({ length: 100 }, (_, i) => 1 + (i * (5 - 1)) / 99);

  // Calculate y values for the Gaussian distribution
  const yValues = xValues.map(x => 
    (1 / (std * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * ((x - mean) / std) ** 2)
  );

  const data = {
    labels: xValues,
    datasets: [
      {
        label: 'PDF',
        data: yValues,
        borderColor: 'blue',
        backgroundColor: 'rgba(75, 192, 192, 0.4)',
        pointRadius: 0, // Hide points
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        min: 1,
        max: 5,
        type: 'linear',
        title: {
          display: false,
          text: 'X Value',
        },
        ticks: {
          stepSize: 1,
          display: false,
        },
        grid: {
          display: false, // Hide y-axis grid lines
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: 'Probability Density',
        },
        ticks: {
          display: false, // Hide x-axis labels
        },
        grid: {
          display: false, // Hide y-axis grid lines
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `f(x) = ${context.raw.toFixed(4)}`,
        },
      },
    },
    maintainAspectRatio: true, // Ensure we keep a good aspect ratio
    aspectRatio: 3, // Custom aspect ratio for taller chart area
  };

  return <PDFOuter>
    <Line data={data} options={options} />
  </PDFOuter>;
};


const PDFOuter = styled.div`
  width: 150px;
  height: 50px; // Set the height to 75px
  overflow: hidden; // Optional: in case you want to ensure no overflow
`;
