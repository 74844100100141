export const FinalReferences = () => {
  const logRegUrl = 'https://web.stanford.edu/class/archive/cs/cs109/cs109.1252/handouts/logRegressionDoc.pdf'
  const extraMLE = 'https://cs109psets.netlify.app/fall24/extra_mle/splash'
  const pepHandout = 'https://web.stanford.edu/class/archive/cs/cs109/cs109.1252/handouts/pepFinal24'

  return <>
    <h3>Resources from the TA</h3>
    <ul>
    <li><a href={pepHandout} target='_blank' rel='noreferrer'>PEP Final Handout</a></li>
      <li><a href={logRegUrl} target='_blank' rel='noreferrer'>Logistic Regression Compiled</a></li>
      <li><a href={extraMLE} target='_blank' rel='noreferrer'>Extra MLE Practice</a></li>
      
    </ul>
  
  </>
}