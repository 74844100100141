import React, { useEffect, useState } from "react";
import "firebase/compat/auth";
import { functions } from "firebaseApp";
import { doc, getFirestore } from "firebase/firestore";
import { useUser } from "contexts/UserContext";
import { useQuarterId } from "hooks/UseQuarterId";
import { useDocumentData, useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";


const defaultData = {
  slotsData: null,
  signupsData: null,
  classData: null,
  slotToStudent: null,
  isPepTeacherContextLoading: false,
  isPepTeacherContextError: null,
};

export const PepTeacherContext = React.createContext(defaultData);
export const PepTeacherProvider = ({children}) => {

  console.log("PepTeacherProvider")

  const db = getFirestore();

  const qtrId = useQuarterId()
  const examId = useParams().examId

  const slotsPath = `personalexamprep/${qtrId}/${examId}/slots`
  const signupsPath = `personalexamprep/${qtrId}/${examId}/signups`

  const [slotsData, slotsLoading, slotsError] = useDocumentData(doc(db, slotsPath));
  const [signupsData, signupsLoading, signupsError] = useDocumentData(doc(db, signupsPath));
  var [classData, classLoading, classLoadingErr] = useDocumentDataOnce(doc(db, `class/${qtrId}/`));
  

  const isPepTeacherContextLoading = slotsLoading || signupsLoading || classLoading;
  const isPepTeacherContextError = slotsError || signupsError || classLoadingErr;

  const slotToStudent = reverseMap(signupsData);

  
  return (
    <PepTeacherContext.Provider
      value={{
        isPepTeacherContextLoading,
        isPepTeacherContextError,
        slotsData,
        signupsData,
        classData,
        slotToStudent
      }}
    >
      {children}
    </PepTeacherContext.Provider>
  );
};

const reverseMap = (map) => {
  // assumes that the values are unique
  const reversed = {}
  for (const key in map) {
    reversed[map[key]] = key
  }
  return reversed
}

