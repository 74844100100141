import React, { useCallback, useEffect, useState } from "react";
import "firebase/compat/auth";
import { functions } from "firebaseApp";
import { useCollection, useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { collection, doc, getFirestore } from "firebase/firestore";
import { useQuarterId } from "hooks/UseQuarterId";


const defaultData = {
  translationsLoading: true,
  getT:(namespace)=>(txt)=>{return txt}
};

export const TranslationContext = React.createContext(defaultData);
export const TranslationProvider = ({children}) => {

  const db = getFirestore()
  const qtrId = useQuarterId()

  // pages can opt to use their own namespace
  const namespacePath = `translation/es/${qtrId}` // collection of translations
  const [namespaceCollection, namespaceCollectionLoading] = useCollection(collection(db, namespacePath))
  const namespaceDictionary = buildNamespaceDictionary(namespaceCollection)

  const getT = (namespace) => {
    return (txt) => {
      // first check the namespace dictionary
      const namespaceLookup = namespaceDictionary[namespace]?.[txt]
      if(namespaceLookup) return namespaceLookup

      // fall back to a global namespace if that exists
      const globalNamespace = namespaceDictionary["global"]
      const globalTranslation = globalNamespace?.[txt]
      return globalTranslation || txt
    }
  }

  const translationsLoading = namespaceCollectionLoading

  return (
    <TranslationContext.Provider
      value={{
        translationsLoading,
        getT
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

const buildNamespaceDictionary = (namespaceCollectionData) => {
  const namespaceDictionary = {}
  if(!namespaceCollectionData) return namespaceDictionary
  namespaceCollectionData.forEach((doc) => {
    console.log("doc", doc)
    const namespace = doc.id
    namespaceDictionary[namespace] = doc.data()
  })
  return namespaceDictionary
}
