import styled from "styled-components"
import { PublicLayout } from "../PublicLayout"
import { Roadmap } from "../roadmap/Roadmap"

export const PublicHome = () => {
  return <PublicLayout
    mainColumn={<MainColumn />}
    rightColumn={<RightColumn />}
    singleColumn={<>
      <MainColumn />
      <RightColumn />
    </>}
  />
}

const MainColumn = () => {
  return <>
    <Roadmap/>
  </>
}

const RightColumn = () => {
  return <div>
    <HomeSubtleTitle>Useful Links</HomeSubtleTitle>
  </div>
}

const HomeSubtleTitle = styled.h2`
  margin-bottom: 20px;
  font-size: 1.5em;
  font-weight: 700;
  color: rgb(109, 109, 109);
`