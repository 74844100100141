import { Node, mergeAttributes, InputRule } from '@tiptap/core'
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react'
import katex from 'katex'
import { useEffect, useRef } from 'react'
// import useState as useStateRef
import useState from 'react-usestateref'

export const MathInline = Node.create({
  name: 'math_inline',
  group: 'inline',
  inline: true,
  atom: true,

  addAttributes() {
    return {
        value: {
            default: '',
          },
          showEditor: {
            default: false 
          }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-type="math-inline"]',
        getAttrs: dom => ({ value: dom.getAttribute('data-value') }),
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(HTMLAttributes, {
        'data-type': 'math-inline',
        'data-value': node.attrs.value,
        'class': 'math-inline',
        contenteditable: 'false',
      }),
    ]
  },

  renderText({ node }) {
    return `$${node.attrs.value}$`
  },

  addNodeView() {
    return ReactNodeViewRenderer(EZTexNode)
  },

  addInputRules() {
    return [
      new InputRule({
        find: /\$\$(.*?)\$\$$/, 
        handler: ({ state, range, commands, chain, can, match }) => {
            const { schema } = state
            const [matchedText, value] = match
            const { tr } = state
            const mathInline = schema.nodes.math_inline.create({ value })

            tr.replaceWith(range.from, range.to, mathInline)
            return tr
        }
        }),
    ]
  },
})


const EZTexNode = (props) => {
  const isEditable = props.editor.isEditable;
  const rawTex = props.node.attrs.value;
  const showEditor = props.node.attrs.showEditor;
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && rawTex) {
      try {
        katex.render(rawTex, containerRef.current, {
          displayMode: false,
          throwOnError: false,
        });
      } catch (error) {
        console.error('Error rendering latex', error);
      }
    }
  }, [rawTex, showEditor]);

  const setShowEditor = (newValue) => {
    if (!isEditable && newValue) {
      return;
    }

    if (newValue || rawTex.trim() !== '') {
      props.updateAttributes({
        showEditor: newValue,
        value: rawTex,
      });
    }
  };

  const onInputChange = (newLatex) => {
    props.updateAttributes({
      value: newLatex,
    });
  };

  const toggleShowEditor = () => {
    setShowEditor(!showEditor);
  };

  if (!showEditor) {
    return (
      <NodeViewWrapper as="span" onClick={toggleShowEditor}>
        <span
          datatype="math-inline"
          data-value={rawTex}
          className="math-inline"
          contentEditable="false"
          ref={containerRef}
        ></span>
      </NodeViewWrapper>
    );
  }

  return (
    <NodeViewWrapper as="span">
      <input
        type="text"
        value={rawTex}
        onChange={(e) => onInputChange(e.target.value)}
        className="border border-primary"
        style={{width: "100px"}}
        onBlur={toggleShowEditor}
      />
    </NodeViewWrapper>
  );
};

