import styled from "styled-components";

export const OrSeparator = () => {
  return <Separator>OR</Separator>
}

const Separator = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 800;
  color: #777;
  margin-top:20px;
  margin-bottom: 20px;

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #777;
  }

  &:not(:empty)::before {
    margin-right: .25em;
  }

  &:not(:empty)::after {
    margin-left: .25em;
  }
`;