export const contentTodos = [
  {
    "task": "Probability Core is necessary foundation.",
    "details": "Your main foundation in this class is the material from lecture 4 and 5: Independence, Mutual Exclusion, Bayes, Law of Total Probability etc. You need to know this material inside and out. If you don't, you will have a lot of trouble with the rest of the midterm content.",
  },
  {
    "task": "Random Variables are central.",
    "details": "Random Variables tightly build off core probability and often are a great place to practice digesting word problems. Once you get the hang of them, you will notice there are a lot of similarities between these types of problem. Mastering problems with random variables is incredibly high bang for your buck.",
  },
  {
    "task": "Inference often sets the curve.",
    "details": "It is a critical concept from CS109, which is covered for the first time 10 days before the exam (and it showes up on pset3 and section). It is often the question with the highest variance, and as such the one that has the biggest impact on grades. Perhaps that makes sense: inference questions are (a) hard, (b) new, and (c) combines concepts from the first two parts of the course. Don't forget to practice this critical concept.",
  },
  {
    "task": "Counting has few dependencies.",
    "details": "Counting will be on the midterm, and there will likely be a problem with equally likely outcomes. And those problems can be mind bending. However, often CS109 students over study for counting. It is a small part of the exam (it was only covered in 2 lectures and a bit), and the content has a light dependence on counting. If you are feeling behind start with the Probability Core and Random Variables.",
  },
]
