export const proTips = [
  {
    "task": "Some tips for during the exam (practice these during practice exams):",
    "subtasks": [
      {
        "task": "Exam time management is hard! Learn to pace yourself – don't spend too much time on early problems and neglect later ones."
      },
      {
        "task": "The number of points for a problem is about how many minutes you should spend on it. As you practice, notice if your timings match up with points."
      },
      {
        "task": "Read each problem carefully. Much of the learning curve of CS109 is in translating word problems into math. Treat this as an explicit skill to practice."
      },
      {
        "task": "If possible, don’t leave questions blank. Have a plan for if you get stuck or can't start a problem: write out all the info the problem gives you, identify what the problem asks for, and define events and/or random variables."
      }
    ]
  },
  // {
  //   "task": "What topics do students historically struggle with on the midterm? What's best to study?",
  //   "subtasks": [
  //     {
  //       "task": "Inference sets the curve. Feeling comfortable with the inference problems on all the practice exams is a great bar to set for yourself."
  //     },
  //     {
  //       "task": "As part of inference, the Law of Total Probability with random variables is hard. See the PSet 2 cellphone problem."
  //     },
  //     {
  //       "task": "Historically, students over-focus on memorizing small details (continuity correction, exact boundaries for 'moderate p vs. small p', integrals). Worry less about forgetting to continuity correct; what's worse is leaving a problem blank because you weren't sure how to start. Instead, focus on the 'setup' of problems and honing your problem-solving skills to get over the hurdle of starting problems more easily."
  //     }
  //   ]
  // },
  {
    "task": "Be intentional about your exam prep mindset.",
    "subtasks": [
      {
        "task": "Focus on what you can control: prioritize thoughtfully so that you make the best use of the study time you have. Take practice exams seriously so you can benefit fully from them."
      },
      {
        "task": "The first step in studying is knowing where you're at. Take your first practice exam early to take stock of where your understanding is rock-solid and where it's a bit rockier. While reviewing problems, ask yourself: could I have solved an equal difficulty problem, from scratch, in reasonable time?"
      },
      {
        "task": "Reflect honestly on what forms of studying would best help expand your academic comfort zone. Avoid ways of studying that might feel more 'reassuring', but that don't challenge you to grow as much (e.g. flashcards, re-watching lecture repeatedly). Realizing that there is a lot left to learn, and feeling uncomfortable with that, is normal! That feeling lets you know when you've found a great opportunity to grow as a student."
      }
    ]
  },
  {
    "task": "Be kind to yourself.",
    "subtasks": [
      {
        "task": "It's easy to feel overwhelmed by how much content we've covered. It was a lot! It's also very normal to find this class and/or studying for exams difficult. Recognize how immensely far you've come already. At the end of the day, the outcome for your CS109 midterm exam is not the end of the world. You have an amazing bright future ahead of you no matter what."
      },
      {
        "task": "We set a high bar for you because we believe that *you* have a really high potential. We give grades knowing that we set a high bar. A large pluraity of students do great at the end of CS109, even though they might not have solved every problem on the midterm (almost everyone still has something to learn). If you take learning step by step, you probably will do great too. Rock on."
      },
      {
        "task": "Don't stress yourself out comparing yourself to others. How well other students seem to be doing can be misleading. Your academic journey is about your growth alone."
      },
      {
        "task": "Don’t forget to have fun!"
      }
    ]
  }
]
