import React, { useEffect, useState } from 'react';
 import Button from 'react-bootstrap/Button'
 import Popover from 'react-bootstrap/Popover'
 import Overlay from 'react-bootstrap/Overlay'
 import {
   useDocumentData,
   useDocumentDataOnce,
   useCollectionOnce,
   useCollection
 } from "react-firebase-hooks/firestore";
 import {
   useParams
 } from "react-router-dom";
 import { v4 as uuidv4 } from 'uuid';
import { FaBookOpen, FaTimes, FaHeart, FaRegHeart } from 'react-icons/fa';
import Swal from 'sweetalert2'
import { firestore } from "firebaseApp.js";
import { collection, deleteDoc, doc, getFirestore } from 'firebase/firestore';


 // ------------------------------------------
 // --- ProblemResources -  Main Component ---
 // ------------------------------------------
 export const ProblemResources = ({user}) => {
   let { qtrId, psetId, qId } = useParams();

   let resourcePath = `resources/${psetId}/${qId}`
   var [resourceCollection, resourceLoading, resourceLoadingErr] = useCollection(
     collection(getFirestore(), resourcePath)
   );




   if(resourceLoading){
    return <FaBookOpen style={resourceButtonStyle}/>
  }


   // grab list of resources already on server
   let serverResources = []
   for (const doc of resourceCollection.docs){
      let resource = doc.data()
      resource['id'] = doc.id
      serverResources.push(resource)
   }
   return <ProblemResourcesWithData
     user={user}
     serverResources={serverResources}
   />
 }

 // ------------------------------------------------------------
 // ------ ProblemResourcesWithData - Secondary Component ------
 // ------------------------------------------------------------
 const ProblemResourcesWithData = ({user, serverResources}) => {
   let { qtrId, psetId, qId } = useParams()

   let resourceEmpty = serverResources.length == 0

   // popover stuff
   const [show, setShow] = useState(false);
   const [target, setTarget] = useState(null);

    // handle voting TODO-- figure this out
    const [heart, setHeartRed] = useState({
    state: false,
    number: '0',
    });

   // handle popover button click
   const handleClick = (event) => {
     setShow(!show);
     setTarget(event.target);


     if(serverResources.length == 0){
         console.log("empty bruh")
     }
   };

   // ---------------------------
   // --- submitting resource ---
   // ---------------------------
   const submitResource = () => {
     // retrieve input vals
     const resourceTitle = document.getElementById("resource-title").value
     let resourceUrl = document.getElementById("resource-url").value

    if(!resourceTitle || !resourceUrl ){
        Swal.fire({
            icon: 'info',
            title: 'Please enter a resource title and url',
        });
        return
    }

    // make sure its not a hacky URL
    if(!isValidHttpUrl(resourceUrl)) {
        resourceUrl = `http://${resourceUrl}`
        if(!isValidHttpUrl(`${resourceUrl}`)) {
            Swal.fire({
                icon: 'info',
                title: `${resourceUrl} is not a valid URL`,
            });
            return
        }

    }

     // clear text from input
     document.getElementById("resource-title").value = ''
     document.getElementById("resource-url").value = ''

     // confirm
     console.log("need to add this boyo now")

     // add to firebase
     addResourceToFirebase(resourceTitle, resourceUrl);
   }

   // the resource needs to be associated to a quarter, pset, problem + user who added it?
   // --------------------------------
   // --- add resource to firebase ---
   // --------------------------------
   const addResourceToFirebase = (title, url) => {
    let uuid = uuidv4()
     firestore
     .collection('resources')
    // change the naming convention? .doc(`${psetId}/${qId}/${uuidv4()}`)
    .doc(`${psetId}/${qId}/${uuid}`)
     .set({
         title: title,
         url: url,
         uid: user,
         votes: "10"
     }, {merge:true})
     .then(() => {
         console.log('resource added!');
     });
   }

    // delete resource: if uid of resource is my id, allow to be deleted -> this should be a rule in firebase too ?
    // ---------------------------
    // ---- removing resource ----
    // ---------------------------
    const removeResource = (resourceId) => {
      deleteDoc(doc(getFirestore(), `resources/${psetId}/${qId}/${resourceId}`))
    }


    // --------------------------------
    // -------- handle votes ----------
    // --------------------------------
    const voteResource = (title) => {
        console.log("vote ")
        setHeartRed((prevState)=>({state:!prevState.state,number:+!+prevState.number+""}))
        console.log(" state: ", heart.state)
        console.log(heart.number)
        updateVotesFirebase(title)
    }

    // record upvotes, need to not allow infinite upvoting though... look into toggle or something for this
    const updateVotesFirebase = (title) => {
        let updatedNum = heart.number
        console.log("UPDATED NUM??: ", updatedNum)

        let db = firestore

        console.log("upvotes yay ")
        // get and then add ?


        // modified from:
        // https://stackoverflow.com/questions/59326985/firebase-react-native-could-not-get-a-document-field-from-firestore-to-display-i
        let docRef = db.collection('resources').doc(`${psetId}/${qId}/${title}_${user}`)
        docRef.get().then(function (doc) {
              if (doc.exists) {
                let voteNum = doc.data()['votes']
                console.log("votes from DB: ", voteNum)
                } else {
                  // doc.data() will be undefined in this case
                  console.log("No such document!");
              }
          }).catch(function (error) {
              console.log("Error getting document:", error);
          });



        // db
        // .collection('resources')
        // .doc(`${psetId}/${qId}/${title}_${user}`)
        // .update({votes: {updatedNum}})
    }



   // styling for voting system
   const voteButtonStyle = {
    marginLeft: "10px",
    cursor: "pointer",
    color: "red"
   }

   return <>

     <FaBookOpen onClick={handleClick} style={resourceButtonStyle} />

     <Overlay
       show={show}
       target={target}
       placement="bottom"
       containerPadding={20}>

       <Popover id="popover-contained">
         <Popover.Header as="h3">Public Resources: </Popover.Header>
         <Popover.Body>

            <span>
                {/* ---- no X version ----  */}
                {/* {resourceEmpty ?
                    (<strong> No resources added yet</strong>)
                    :
                    (<ul id="resource-list" className="list-group">
                    {serverResources.map(resource => <li className="list-group-item">
                    <a href={resource.url}>{resource.title}</a>
                    <FaTimes onClick={removeResource} style={removeButtonStyle} /></li>)}</ul>)
                } */}

                {/* ---- potential X version ----  */}
                {/* Display no resources message if none, otherwise display resources */}
                {resourceEmpty ?
                    (<strong> No resources added yet</strong>)
                    :
                    (<ul id="resource-list" className="list-group">
                    {serverResources.map(resource => <ResourceListItem
                        key ={resource.id}
                        resource={resource}
                        user = {user}
                        removeResource={removeResource}
                    />
                    )}</ul>)
                }

                <hr/>
                <input
                    id="resource-title"
                    type="text"
                    placeholder="Resource Title"
                    style={{width: "243px"}}
                    className='resourceInput'
                ></input>

                <input
                    id="resource-url"
                    type="text"
                    placeholder="URL"
                    style={{width: "243px"}}
                    className='resourceInput'
                ></input>

                <Button onClick={submitResource} variant="light" style={{marginLeft: "55px"}}>
                Add Resource
                </Button>

            </span>

         </Popover.Body>
       </Popover>
     </Overlay>

   </>
 }

// validate urls
const isValidHttpUrl = (string) => {
    let parts = string.split('.')
    // there isn't a . in the string
    if(parts.length < 2) {
        return false
    }
    // the last part is an empty string
    if(!parts[parts.length - 1]) {
        return false
    }

    // classic sanitization
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

 // resource button styling
 const resourceButtonStyle = {
    marginRight: "5px",
    cursor: "pointer",
    color: "#0000ff"
}

 // resource button styling
 const resourceButtonStyleLoading = {
    marginRight: "5px",
    cursor: "pointer",
    color: "black"
}

// styling needs fixed with X placement
const removeButtonStyle = {
    cursor: "pointer",
    color: "#0000ff"
}

const ResourceListItem = ({resource, user, removeResource}) => {
    return <li className="list-group-item d-flex justify-content-between align-items-center">
        <a target="_blank" href={`${resource.url}`}>{resource.title}</a>


        {/* <div>
            {heart.state ?
                (<FaHeart onClick={() => voteResource(resource.title)} style={voteButtonStyle} />)
                :
                (<FaRegHeart onClick={() => voteResource(resource.title)} style={voteButtonStyle} />)
            }
        </div> */}

        {/* if this is my resource allow me to remove it */}
        {(resource.uid === user) ?
            (<FaTimes onClick={() => removeResource(resource.id)} style={removeButtonStyle} />)
            :
            <></>
        }
    </li>
 }