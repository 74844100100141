import React, { useState } from 'react';
import {
  Sidebar,
  Menu,
  SubMenu,
  MenuItem,
  sidebarClasses
} from 'react-pro-sidebar';
import {
  useParams
} from "react-router-dom";
import { Link } from 'react-router-dom';
import { FaCheck, FaHome, FaBars, FaUser, FaHeart, FaFlag, FaChevronRight, FaChevronLeft, FaUsers, FaHandshake } from 'react-icons/fa';
import "bootstrap/dist/css/bootstrap.min.css";
import { auth } from "firebaseApp.js";
import { NumOnlineMenuItem } from 'components/colearning/NumOnlineMenuItem';


export const Aside = ({ user, studentPsetData, toggled, setToggled, publicPsetData }) => {
  const [collapsed, setCollapsed] = useState(true);
  let corrects = studentPsetData['corrects']
  let publicQuestionInfo = publicPsetData['questionInfo']
  return <AsideWithData
    user={user}
    toggled={toggled}
    collapsed={collapsed}
    corrects={corrects}
    publicPsetData={publicPsetData}
    publicQuestionInfo={publicQuestionInfo}
    setCollapsed={setCollapsed}
    setToggled={setToggled}
  />
}

const AsideWithData = ({ user, corrects, publicQuestionInfo, publicPsetData, toggled, setToggled, collapsed, setCollapsed }) => {
  let { qtrId, psetId, qId } = useParams();
  return (<>
    <Sidebar
      backgroundColor={menuBackgroundColor}
      toggled={toggled}
      collapsed={collapsed}
      breakPoint="md"
      rootStyles={sidebarRootStyles}
      onBackdropClick={() => setToggled(false)}
    >
      <div>
        <Header
          collapsed={collapsed}
          publicPsetData={publicPsetData}
        />

        <QuestionLinks
          publicQuestionInfo={publicQuestionInfo}
          corrects={corrects}
          collapsed={collapsed}
          psetId={psetId}
          qtrId={qtrId}
        />
      </div>

      <Footer
        collapsed={collapsed}
        setCollapsed={setCollapsed}
      />

    </Sidebar>
  </>
  )
}

const Footer = ({ collapsed, setCollapsed }) => {
  let { qtrId, psetId, qId } = useParams();
  return <div style={footerOuterStyle}>

    <Menu menuItemStyles={questionsMenuStyle}>

      <MenuItem
        icon={<span><FaFlag /></span>}
        active={qId === 'submit'}
        component={<Link to={`/${qtrId}/${psetId}/submit`} />}
      >
        Submit
        
      </MenuItem>


      <SubMenu label="User" icon={<FaUser />} >
        <MenuItem
          component={<Link to={`/${qtrId}`} />}
        >All Psets
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteAllCookies()
            auth().signOut()
          }}
        >Logout</MenuItem>
      </SubMenu>

      <MenuItem
        icon={collapsed ? <FaChevronRight /> : <FaChevronLeft />}
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? 'Expand' : 'Minimize'}

      </MenuItem>
      <NumOnlineMenuItem collapsed={collapsed} />
    </Menu>


  </div >
}

const Header = ({ collapsed, publicPsetData }) => {
  let { qtrId, psetId, qId, forcedStudentId } = useParams();

  let toUrl = `/${qtrId}/${psetId}/splash`
  if (forcedStudentId) {
    toUrl += `/${forcedStudentId}`
  }

  return <div>
    <h4 style={classNameStyle}>
      {collapsed ? publicPsetData['sidebarShort'] : publicPsetData['sidebarLong']}
    </h4>
    <Menu menuItemStyles={headerMenuStyle}>
      <MenuItem
        icon={<span><FaHome /></span>}
        active={qId === 'splash'}
        component={<Link to={toUrl} />}
      >
        Home
      </MenuItem>

    </Menu>
  </div>
}

const QuestionLinks = ({ publicQuestionInfo, corrects, collapsed, qtrId, psetId }) => {
  let currIndex = 0
  let lastBaseName = '' // notice when a subpart is finished
  return <>
    <Menu menuItemStyles={questionsMenuStyle}>
      {
        publicQuestionInfo.map(function (question, index) {

          let qId = question.qId
          let parts = qId.split('__')
          let currBaseName = parts[0]
          if (currBaseName != lastBaseName) {
            currIndex += 1
          }
          let iconText = currIndex
          if (qId.includes('__')) {
            let subpartTag = parts[1]
            iconText += subpartTag
          }
          lastBaseName = currBaseName


          return <QuestionMenuItem
            key={index}
            questionInfo={question}
            corrects={corrects}
            iconText={iconText}
            collapsed={collapsed}
            qtrId={qtrId}
            psetId={psetId}
          />
        })
      }
    </Menu>
  </>

}

const QuestionMenuItem = ({ questionInfo, qtrId, psetId, collapsed, corrects, iconText }) => {
  let itemQId = questionInfo['qId']
  let { qId, forcedStudentId } = useParams();
  let isActive = itemQId == qId
  // if its in the correct dictionary and has the value true
  let isCorrect = itemQId in corrects && corrects[itemQId]
  var suffix = <span />
  var collapsedSuffix = <span />
  if (isCorrect) {
    suffix = <span className="badge bg-success rounded-pill"><FaCheck /></span>
    if (collapsed) {
      collapsedSuffix = <span className="position-absolute top-50 ml-4 start-50 translate-middle-y badge rounded-pill bg-success"><FaCheck /><span className="visually-hidden">correct</span></span>
    }
  }

  let toUrl = `/${qtrId}/${psetId}/${itemQId}`
  if (forcedStudentId) {
    toUrl += `/${forcedStudentId}`
  }

  return (
    <MenuItem
      active={isActive}
      icon={<span>{iconText}{collapsedSuffix}</span>}
      suffix={suffix}
      component={<Link to={toUrl} />}
    >
      {questionInfo['title']}
    </MenuItem>
  )
}

const SubpartMenuItem = ({ questionInfo, qtrId, psetId, collapsed, corrects, index }) => {
  return (
    <SubMenu title="Components" icon={<span>5</span>}>
      <MenuItem icon={<span>a</span>}></MenuItem>
      <MenuItem icon={<span>b</span>}></MenuItem>
    </SubMenu>
  )
}

function getInitials(name) {
  if (!name) return ''
  return name.split(" ").map((n) => n[0]).join("");
}

function deleteAllCookies() {
  // NOTE: this was an attempt to make logout actually log-out of stanford
  // but it didn't work. Consider deleting this whole function...
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}


const menuBackgroundColor = "#002154"
const iconBackgroundColor = 'rgba(255, 255, 255, 0.2)'
const iconColor = '#b3b8d4'

const questionsMenuStyle = {
  root: {
    color: iconColor,
  },
  button: ({ active }) => {
    return {
      height: '42px',
      color: active ? 'black' : iconColor,
      fontWeight: 500,
      '&:hover': {
        color: active ? 'black' : 'white',
        backgroundColor: menuBackgroundColor,
      },
    }
  },
  label: ({ active }) => {
    return {
      color: active ? 'white' : iconColor,
    }
  },
  icon: ({ active }) => {
    return {
      backgroundColor: active ? 'white' : iconBackgroundColor,
      borderRadius: '50%',
    }
  },
  subMenuContent: {
    backgroundColor: menuBackgroundColor,
    color: iconColor,
  }
}

const headerMenuStyle = {
  root: {
    color: iconColor,
  },
  button: ({ active }) => {
    return {
      backgroundColor: menuBackgroundColor,
      height: '40px',
      marginBottom: '5px',
      color: active ? 'black' : iconColor,
      '&:hover': {
        backgroundColor: menuBackgroundColor,
        color: active ? 'black' : 'white',
      },
    }
  },
  label: ({ active }) => {
    return {
      color: active ? 'white' : iconColor,
    }
  },
  icon: ({ active }) => {
    return {
      backgroundColor: active ? 'white' : iconBackgroundColor,
      borderRadius: '5px',
    }
  },
}

const classNameStyle = {
  color: '#b3b8d4',
  marginLeft: '17px',
  paddingBottom: '0px',
  marginTop: '10px',
  marginBottom: '0px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}

const sidebarRootStyles = {
  [`.${sidebarClasses.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}

const footerOuterStyle = {
  paddingTop: '5px',
  borderTop: `1px solid ${iconBackgroundColor}`,
}