import { useQuarterId } from "hooks/UseQuarterId"
import { TimeSignup } from "./TimeSignup"
import { useUser } from "contexts/UserContext"
import { RichTextEditor } from "components/richText/RichTextEditor"
import { Handout } from "components/handout/Handout"
import styled from "styled-components"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { doc, getFirestore } from "firebase/firestore"
import { Link } from "react-router-dom"
import { TimeContext, TimeProvider } from "contexts/TimeContext"
import { useContext } from "react"

// `personalexamprep/${qtrId}/${examId}/students/${studentId}/`

export const PersonalizedFinalPrep = () => {
  return <TimeProvider><Handout
    element={<PersonalizedFinalPrepInner />}
  />
  </TimeProvider>
}

const PersonalizedFinalPrepInner = () => {
  // const {serverTimeLoaded} = useContext(TimeContext)
  const examId = 'final'
  const qtrId = useQuarterId()


  return <>
    <h1>Personalized Exam Prep Signup: Final</h1>
    <p>
      PEP is back for the final. The only difference is that Final PEP is 15 mins long.
    </p>
    <div className="alert alert-primary"><p className="text-align-justify">
      <b>What is PEP?</b><br/>
      This quarter we are trying something <Blue>new in CS109</Blue>! In the past we have talked to many students <i>after</i> the final to get feedback on how they studied and what they found challenging. We often have some good insights for students, but it can feel like those insights are a few weeks too late. This quarter we are trying to get you those insights <b><i>before</i></b> the final so you can master the material more effectively. We call these 1:1s <Blue>Personalized Exam Prep</Blue>.
    </p>
    <p>You meet in-person with a TA a week before the final for <b>15 mins</b>. You don't need to prepare or bring anything. The TA gets to know you and, after the session, sends you home with a draft of a study plan. Participating will get you an automatic 4 points on the final. If you can't participate, that is fine, your final will be graded as usual. You likely will not get your section TA, but it is possible (what is the probability???).</p></div>

    <PublishedGuide/>
    <hr/>

    

    <TimeSignup
      slotsPath={`personalexamprep/${qtrId}/${examId}/slots`}
      signupsPath={`personalexamprep/${qtrId}/${examId}/signups`}
      examId={'final'}
      lengthMins={15}
    />
    {/* <hr/> */}
    {/* <OnboardingForm editable={true}/> */}
  </>


}

const PublishedGuide = () => {
  const qtrId = useQuarterId()
  const studentId = useUser().uid

  // personalexamprep/fall24/final/students/RBMulJglZEVhM9QnvGMoS9xlLU03/private/ta/scores
  const taScoresPath = `personalexamprep/${qtrId}/final/students/${studentId}/private/ta/scores`

  const [taScores, taScoresLoading, taScoresError] = useDocumentData(doc(getFirestore(), taScoresPath))

  if(taScoresLoading || taScoresError) {
    return <></>
  }

  const isPublished = taScores?.isPublished

  if(isPublished) {
    const publishedUrl = `/${qtrId}/personalprep/final/guide/${studentId}`
    return <Link to={publishedUrl}className="btn btn-primary btn-lg">View My Personalized Guide</Link>
  }
  return <></>

}

const OnboardingForm = ({editable}) => {

  const examId = 'final'
  const qtrId = useQuarterId()
  const userId = useUser().uid

  return <>
  <h2>Pre Meeting Questions</h2>
  <p>Please fill these out before your meeting. Responses (should) autosave</p>
    <Question
      prompt="How has learning CS109 material been going for you so far?"
      firebaseDocPath={`personalexamprep/${qtrId}/${examId}/students/${userId}/howsLearningGoing`}
      editable={editable}
    />
    <Question
      prompt="How are you planning to study for the final?"
      firebaseDocPath={`personalexamprep/${qtrId}/${examId}/students/${userId}/howStudy`}
      editable={editable}
    />
    <Question
      prompt="What sort of advice from a TA would help you most?"
      firebaseDocPath={`personalexamprep/${qtrId}/${examId}/students/${userId}/whatAdvice`}
      editable={editable}
    />
    <Question
      prompt="When you get stuck on a pset problem, what do you do?"
      firebaseDocPath={`personalexamprep/${qtrId}/${examId}/students/${userId}/stuckOnPset`}
      editable={editable}
    />
    
  </>

}

const Question = ({ prompt, firebaseDocPath, editable }) => {
  const userId = useUser().uid
  return <>
    <h5>{prompt}</h5>
    <RichTextEditor
      firebaseDocPath={firebaseDocPath}
      collaborative={true}
      editable={editable}
      contentKey={'content'}
      user={userId}
    />
    <div style={{height: '1em'}}></div>
  </>
}

const Blue = styled.b`
  color: blue;
`