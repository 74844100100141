import { Editor } from '@monaco-editor/react';
import React, { useState, useEffect } from 'react';


export const CodeEditor = (props) => {

    const handleEditorDidMount = (editor, monaco) => {
        editor.focus();
    }

    return <Editor
    width={"100%"}
    value={props.value}
    onChange={(e) => props.onChange(e)}
    defaultLanguage={'python'}
    options={{
      readOnly: !props.editable,
      fontSize: 14,
      padding: "0",
      scrollBeyondLastColomn: false,
      scrollBeyondLastLine: false,
      lineNumbersMinChars: 2,  // Set this to a lower value to decrease gutter size
      scrollbar: {
        horizontal: 'hidden',
        alwaysConsumeMouseWheel: false,
      },
      minimap: {
        enabled: false
      },
    }}

    onMount={handleEditorDidMount}
  />;

    // We recently upgraded to monaco which is great for screen readers
    // // ace is not great for screen readers
    // let screenReader = props.userMetaData && props.userMetaData.screenReader
    // if(screenReader) {
    //     function textAreaChange(e) {
    //         let newValue = e.target.value
    //         props.onChange(newValue)
    //     }
    //     return <textarea 
    //         className="screenReaderEditor"
    //         key = {props.qId}
    //         type="text"
    //         value={props.value}
    //         onChange={(e) => textAreaChange(e)}
    //         style={{width:'100%', flexGrow:2}}
    //     ></textarea>
    // }


    // return <AceEditor
    //     mode="python"
    //     key = {props.qId}
    //     value={`${props.value}`}
    //     onChange={(e) => props.onChange(e)}
    //     width="100%"
    //     mode="python"
    //     fontSize="13px"
    //     enableBasicAutocompletion={true}
    //     enableLiveAutocompletion={true}
    //     enableSnippets={true}
    //     readOnly = {!props.editable}
    //     wrapEnabled={true}
    //     maxLines={props.focusMode ? null : Infinity}
    //     style={{width:'100%', flexGrow:2}}
    // />
}