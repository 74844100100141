import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styled from 'styled-components';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const VoteHistogram = ({ scores }) => {
  // const scores = [1, 2, 3, 4,5, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5];
  // Count occurrences of each score from 1 to 5
  const voteCounts = [1, 2, 3, 4, 5].map(
    (vote) => scores.filter((score) => score === vote).length
  );

  const data = {
    labels: ['1', '2', '3', '4', '5'],
    datasets: [
      {
        label: 'Vote Count',
        data: voteCounts,
        backgroundColor: 'rgba(0, 0, 255, 0.4)',
        borderColor: 'blue',
        borderWidth: 0,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: false,
          // text: 'Number of Votes',
        },
        ticks: {
          display: false, // Hide x-axis labels
        },
        grid: {
          display: false, // Hide y-axis grid lines
        },
      },
      x: {
        title: {
          display: false,
          text: 'Vote Value',
        },
        ticks: {
          display: false, // Hide x-axis labels
        },
        grid: {
          display: false, // Hide y-axis grid lines
        },
        barPercentage: 1.0, // Remove gap between bars
        categoryPercentage: 1.0, // Remove gap between bars
      },
    },
    layout: {
      padding: {
        top: 0, // Reduce padding above the chart
        bottom: 0, // Reduce padding below the chart
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false, // Allow custom height and width
    barThickness: 'flex', // Ensures bars fill available space
    datasets: {
      bar: {
        borderWidth: 0, // Remove border to eliminate gap
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
    },
  };

  return <HistogramOuter>
    <Bar data={data} options={options} />
  </HistogramOuter>;
};

const HistogramOuter = styled.div`
  width: 150px;
  height: 50px; // Set the height to 75px
  overflow: hidden; // Optional: in case you want to ensure no overflow
`;

export default VoteHistogram;
