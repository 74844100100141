import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const Handout = ({ element }) => {

  const [height, setHeight] = useState('100vh');

  const handoutRef = useRef(null);

  useEffect(() => {
    const handoutHeight = handoutRef.current.offsetHeight;
    const newHeight = `max(${handoutHeight}px, 100vh)`;
    setHeight(newHeight);
  }, [element]);

  return (
    <GreyBackground style={{ minHeight: height }}>
      <div className="container-fluid">
        <HandoutRow className="row">
          <HandoutInner className="col" ref={handoutRef}>
            <div className="pb-2 mb-2">
              {element}
            </div>
          </HandoutInner>
        </HandoutRow>
      </div>
    </GreyBackground>
  );
}

const HandoutInner = styled.div`
  background-color: white;
  padding:100px;
  padding-top:30px;
  max-width: 930px;
  margin-top:15px;
  margin-bottom:50px;

  @media (max-width: 900px) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 700px) {
    padding: 20px;
  }

  @media (max-width: 500px) {
    padding: 10px;
`

const HandoutRow = styled.div`
  display: flex;
  justify-content: center;
`

const GreyBackground = styled.div`
  background-color: #535659;
  padding-bottom: 20px;
  width: 100vw;
  `