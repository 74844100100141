import { Outlet } from "react-router-dom"
import { PepTeacherContext, PepTeacherProvider } from "./ExamPrepTeacherContext"
import { useContext } from "react"
import { TranslationContext, TranslationProvider } from "contexts/TranslationContext"

export const ExamPrepTeacherOuter = () => {
  console.log("ExamPrepTeacherOuter")
  return <>
    <TranslationProvider>
    <PepTeacherProvider>
      <Inner/>
    </PepTeacherProvider>
    </TranslationProvider>
  </>
}

const Inner = () => {

  const {
    isPepTeacherContextLoading,
    isPepTeacherContextError,
  } = useContext(PepTeacherContext);

  const {
    translationsLoading
  } = useContext(TranslationContext);

  if(isPepTeacherContextLoading){
    return <></>
  }

  if(translationsLoading){
    return <></>
  }

  if(isPepTeacherContextError){
    return <p>Error 500: {isPepTeacherContextError}</p>
  }

  return <Outlet/>
}