import { useEffect, useState } from "react";

import { getFunctions, httpsCallable } from "firebase/functions";
import API_ROUTE from "ApiRoute";
import axios from "axios";
import { useUser } from "contexts/UserContext";
import { doc, getFirestore } from "firebase/firestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { RateSongs, RatingTitle } from "./components/RateSongs";
import { Handout } from "components/handout/Handout";
import { useQuarterId } from "hooks/UseQuarterId";
import { EliminationRoundVoting } from "./EliminationRoundVoting";
import { Link } from "react-router-dom";
const functions = getFunctions();

export const MusicSplash = () => {
  /*
  The main job of MusicSplash is to decide if we should show
  1. song suggestion
  2. regular voting
  3. round voting
  */

  // const qtrId = useQuarterId();

  // return <Handout element={<><h3>First Round has Closed</h3>
  // <hr/>
  // <p>See the results here:
  //   <Link to = {`/${qtrId}/music/ratings`}> Round 1 Results</Link>
  // </p>
  // Elimination Round Voting Coming Soon!!!</>
  // } />
  return <EliminationRoundVoting round={5}/>

}

