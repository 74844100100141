
import { Navigate } from 'react-router-dom'

import {
  useParams
} from "react-router-dom";

export const PSetHome = (props) => {

  // get the first question in the pset and redirect to it

  let { qtrId, psetId } = useParams();

  return <Navigate
    to={`/${qtrId}/${psetId}/splash`}
  />
}