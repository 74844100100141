import { ROADMAP } from 'public/roadmap/RoadmapSchema';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

interface RoadmapModule {
  thumbnailUrl: string,
  title: string,
  items: RoadmapItem[]
}

interface RoadmapItem {
  itemType: string,
  selfCheck: boolean,
  completionPath?: string,
  title: string,
  id: string
}

interface RoadmapContextType {
  roadmapItemMap: { [key: string]: RoadmapModule },
}


// Create the context with initial undefined value
export const RoadmapContext = createContext<RoadmapContextType | undefined>(undefined);

interface RoadmapProviderProps {
  children: ReactNode;
  courseId: string;
}


export const RoadmapProvider: React.FC<RoadmapProviderProps> = ({ courseId, children }) => {

  const [roadmapItemMap, setRoadmapItemMap] = useState(publicRoadmap)


  return (
    <RoadmapContext.Provider value={{
      roadmapItemMap,
    }}>
      {children}
    </RoadmapContext.Provider>
  )
}

const publicRoadmap: { [key: string]: RoadmapModule } = {
  'counting': {
    title: 'Counting',
    thumbnailUrl: '/psets/pset1.jpg',
    items: [
      {
        itemType: ROADMAP.LESSON.key,
        selfCheck: true,
        title: 'Counting Lesson',
        id: 'counting-lesson-1'
      },
      {
        itemType: 'Lesson',
        selfCheck: true,
        title: 'Counting Lesson',
        id: 'counting-lesson-1'
      },
      {
        itemType: 'Assignment',
        selfCheck: true,
        title: 'Counting',
        id: 'counting-pset'
      }
    ],
  },
  'probability': {
    title:'Probability',
    thumbnailUrl:'/campus/gates.jpg',
    items: [
      {
        itemType:'Lesson',
        selfCheck: true,
        title: 'Intro to Probability',
        id: 'probability-1'
      }
    ]
  }
}