import { arrayUnion, doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";

export function logPageVisit(user, pathname) {
  if(!pathname || !user) {
    return
  }

  try {
    let pathnameAsKey = pathname.replaceAll("/", "-");
    const path = `users/${user.uid}/engagement/${pathnameAsKey}`;
    const logRef = doc(getFirestore(), path);
    const lastRef = doc(getFirestore(), `users/${user.uid}/engagement/lastEngagement`);
    setDoc(logRef, {
      timestamps: arrayUnion(new Date()),
    }, {merge: true});
    setDoc(lastRef, {
      lastPage: pathname,
      lastVisit: Date.now(),
    }, {merge: true});
  } catch (e) {
    console.error(e);
  }
}