import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  getFirestore,
  query,
  serverTimestamp,
} from "firebase/firestore";
import "firebase/analytics";
import firebase from "firebase/compat/app";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useParams } from "react-router-dom";
import { Loading } from "../loading/Loading.js";
import { Login } from "./Login.js";
import { auth, firestore, database } from "firebaseApp.js";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { logPageVisit } from "./Logging.js";
import { useQuarterId } from "hooks/UseQuarterId";
import { UserProvider } from "contexts/UserContext.js";
import { getAuth } from "firebase/auth";

export const UserMetaDataContext = createContext(undefined)

export const Authenticated = ({ component: Component, ...props }) => {
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);

  const location = useLocation();
  let pathname = location.pathname;


  const qtrId = useQuarterId()

  // keep track of who is online
  useEffect(() => {
    if (user) {
      userPresence(user, qtrId)
    }
  }, [user, qtrId])

  // only log when pathname changes
  useEffect(() => {
    // don't crash on logging...
    logPageVisit(user, pathname)
    
  }, [user, pathname]);

  if (loading) {
    return <Loading />;
  }

  if (!user) {
    return <Login />;
  }

  // if the user is trying to get to a stanford page, without a stanford email, log them out
  if (user.email.split("@")[1] !== "stanford.edu") {
    // if the class is not the public class
    if (qtrId !== 'public') {
      auth.signOut();
      return <></>;
    }
  }

  return (
    <UserProvider>
      <Component />
    </UserProvider>
  )
};

const userPresence = (user, qtrId) => {

  // only track if the qtrId is not undefined
  if (qtrId === undefined) {
    return
  }
  const userStatusDatabaseRef = database.ref(`/${qtrId}/status/${user.uid}`);
  const isOfflineForDatabase = {
    state: 'offline',
    roomId: null,
    last_changed: serverTimestamp(),
  };
  const isOnlineForDatabase = {
    state: 'online',
    roomId: null,
    last_changed: serverTimestamp(),
  };
  database.ref('.info/connected').on('value', function (snapshot) {
    // If we're not currently connected, don't do anything.
    if (snapshot.val() === false) {
      return;
    };

    // If we are currently connected, then use the 'onDisconnect()'
    // method to add a set which will only trigger once this
    // client has disconnected by closing the app,
    // losing internet, or any other means.
    userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
      // The promise returned from .onDisconnect().set() will
      // resolve as soon as the server acknowledges the onDisconnect()
      // request, NOT once we've actually disconnected:
      // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

      // We can now safely set ourselves as 'online' knowing that the
      // server will mark us as offline once we lose connection.
      userStatusDatabaseRef.update(isOnlineForDatabase);
    });
  });
}
